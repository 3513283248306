import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Wrap,
  WrapItem,
  useDisclosure,
  Stack,
  Flex,
  Heading,
  Text,
  Card,
  Center,
  Spinner,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { VEHICLE_TYPE, ambulanceMapper } from "../../config/constant";
import { Icon } from "@iconify/react";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useOnePAmbulance } from "@hooks/api/useAmbulance";
import { ISelectedVehicleType } from "@config/types";
import AmbulanceVariant from "@components/molecules/AmbulanceVariant";
import { getProperty } from "dot-prop";
import { createCaseVehicleTypeSelect, createCaseVehicleVariantSelect } from "@config/event";

export default function AmbulanceTypeSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const nearByVehicle: any = useRedStore(state => state.nearbyVehicle);

  const { __updateVehicleVariant, __updateSelectedVariant, __resetVehicleInfo } = useOrderAction();

  const entityObj: any = localOrderChanges?.entityRequired && Array.isArray(localOrderChanges?.entityRequired) && localOrderChanges?.entityRequired?.length > 0 && localOrderChanges?.entityRequired[0];

  const subtype = entityObj?.subtype;

  const onButtonClick = (obj: any) => {
    __updateSelectedVariant(obj);
    const variantValue = getProperty(obj, "variant[0].value", "");
    const entityType: any = getProperty(localOrderChanges, "entityRequired[0].subtype", "") || "";

    createCaseVehicleTypeSelect({ vehicleType: obj?.value, orderId: localOrderChanges?.orderId });

    if (!entityType || !entityType?.toString()?.toLocaleLowerCase().includes(variantValue?.toString()?.toLocaleLowerCase())) {
      __updateVehicleVariant({ variant: variantValue });
    }
    onOpen();
  };

  const onVariantSelect = (variant: any) => {
    const _price = (nearByVehicle && variant?.value && nearByVehicle[variant?.value]?.price) || 0;
    __updateVehicleVariant({ variant: variant.value, price: _price });
    createCaseVehicleVariantSelect({ variant: variant?.value, price: _price, orderId: localOrderChanges?.orderId });
    handleDrawerClose();
  };

  const handleDrawerClose = () => {
    __resetVehicleInfo();
    onClose();
  };

  return (
    <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"}>
      <Wrap>
        {VEHICLE_TYPE.map((itm, index) => (
          <WrapItem key={index}>
            <Button
              colorScheme="purple"
              px={4}
              h={8}
              borderRadius={"full"}
              alignItems={"center"}
              textTransform={"uppercase"}
              fontSize={"sm"}
              fontWeight={"semibold"}
              gap={2}
              variant={localOrderChanges._selectedVehicleType?.value === itm.value ? "solid" : "outline"}
              isDisabled={itm.isDisabled}
              onClick={() => onButtonClick(itm)}
            >
              <Icon icon={itm.icon} fontSize={25} />
              {itm.label}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
      {!subtype && localOrderChanges?._selectedVehicleType.value && (
        <Flex bg={"red.50"} px={4} py={2} borderWidth={1} borderColor={"red.300"} borderRadius={"md"} mt={5} align={"center"}>
          <Icon icon={localOrderChanges._selectedVehicleType?.icon} fontSize={32} color="red" />
          <Text ml={2} fontSize={"sm"} fontWeight={"normal"} color={"red"}>
            Please select a vehicle <strong>variant</strong>, such as Standard, Elite, Supreme, or any .
          </Text>
        </Flex>
      )}
      {subtype && (
        <Flex bg={"purple.100"} px={4} py={2} borderWidth={1} borderColor={"purple.300"} borderRadius={"md"} mt={5} align={"center"}>
          <Icon icon={localOrderChanges._selectedVehicleType?.icon} fontSize={25} />
          <Text ml={2} fontSize={"sm"} fontWeight={"normal"}>
            Selected Vehicle is <strong>{ambulanceMapper[subtype] ? ambulanceMapper[subtype] : subtype}</strong>
          </Text>
        </Flex>
      )}
      {/* onClose={handleDrawerClose} */}
      <Drawer placement={"bottom"} isOpen={isOpen} isFullHeight onClose={handleDrawerClose}>
        <DrawerOverlay />
        <DrawerContent bg={"gray.50"}>
          <DrawerHeader borderBottomWidth="1px" display={"flex"} justifyContent={"space-between"}>
            <Text fontSize={"sm"}>Select Vehicle Variant </Text>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody py={4} pt={6} px={4} minH={"57vh"}>
            <DrawerBodyContent onVariantSelect={onVariantSelect} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

const DrawerBodyContent = ({ onVariantSelect }: { onVariantSelect: (itm: any) => any }) => {
  const { ...rest } = useOnePAmbulance();

  const localOrderChanges = useRedStore(state => state.localOrderChanges);

  const entityObj: any = localOrderChanges?.entityRequired && Array.isArray(localOrderChanges?.entityRequired) && localOrderChanges?.entityRequired?.length > 0 && localOrderChanges?.entityRequired[0];

  const subtype = entityObj?.subtype;

  return (
    <>
      {" "}
      {rest?.isFetching ? (
        <Center minH={"57vh"}>
          <Spinner size={"md"} />
        </Center>
      ) : (
        <>
          {localOrderChanges._selectedVehicleType?.variant?.map((itm: ISelectedVehicleType) => (
            <Box key={itm?.value} onClick={() => !itm.isDisabled && onVariantSelect(itm)} opacity={itm.isDisabled ? 0.4 : 1}>
              <AmbulanceVariant selectedVehicle={itm} isSelected={itm.value === subtype} />
            </Box>
          ))}
        </>
      )}
    </>
  );
};
