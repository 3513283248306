import { Box, Flex, Text } from "@chakra-ui/react";
import CustomInputSection from "@components/atom/CustomInputSection";
import { SERVICE_TYPE_MAP } from "@config/constant";
import { Icon } from "@iconify/react";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import { convertPrice, removeUnderscore } from "@utils";
import { getProperty } from "dot-prop";

export default function EnterFareAgreed() {
  const { __updateOverridePriceTo, __updateFareAgreedLocal } = useOrderAction();
  const serviceType = useRedStore(state => state.localOrderChanges.serviceType);
  const overwriteFare = useRedStore(state => state.localOrderChanges.overwriteFare);

  const isOverridenPrice = useRedStore(state => state.order.isOverridenPrice);

  const updatePrice = (price: any) => {
    // __updateOverridePriceTo(price * 100);
    // __updateFareAgreedLocal(price * 100);
    __updateOverridePriceTo(price);
    __updateFareAgreedLocal(price * 100);
  };

  const parsed = overwriteFare ? convertPrice(overwriteFare) : 0;
  return (
    <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
      <Text fontWeight={"normal"} fontSize={"sm"} mb={4}>
        This is the Custom fare mode, where you can set any price according to your preference.
      </Text>

      <Box ml={-2}>
        <CustomInputSection
          icon="fa-solid:rupee-sign"
          title={`${serviceType ? getProperty(SERVICE_TYPE_MAP, serviceType)?.label?.toString() : "-"} Charges`}
          isDisabled={isOverridenPrice}
          // value={overwriteFare}
          onSelect={e => updatePrice(parseInt(isNaN(e?.target?.value) ? 0 : e?.target?.value))}
          defaultValue={parsed || 0}
          maxWidth={150}
          minWidth={120}
        />
        {/* {pricing?.price && (
          <Box mr={2}>
            <Text textAlign={"right"} fontSize={"sm"} fontWeight={"normal"} color={"gray.600"}>
              Recommended Fare: <span style={{ color: "red", fontWeight: 800 }}> ₹{convertPrice(pricing?.price)}</span>
            </Text>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}
