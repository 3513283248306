import { Box, Flex, Heading } from "@chakra-ui/react";

import { Icon } from "@iconify/react";
import { IAddress, ILabelValue } from "../../config/types";
import PlacePicker from "./PlacePicker";
import { GLOBAL_SEARCH_SERVICE_TYPE } from "@config/constant";

interface IPickupDropSection {
  title: string;
  icon: string;
  type: "pickup" | "dropoff";
  onSelect: (address: any) => void;
  sequence: number;
  value?: any;
  withPlaceApi?: boolean;
  defaultValue: any;
  isDefaultPartnerSearchSelect: boolean;
  serviceType: string;
}

export default function GoogleAddressPickup({ title, icon, type, onSelect, sequence, defaultValue, serviceType }: IPickupDropSection) {
  const onSelectHandler = (addressObj: ILabelValue) => {
    const payload = processAddress(addressObj.value);
    onSelect(payload);
  };

  function processAddress(address: IAddress) {
    const geo = {
      lat: address?.geometry?.location?.lat,
      long: address?.geometry?.location.lng,
    };

    const payload: any = {
      geo: geo,
      city: address?.city,
      type,
      sequence,
      address: {
        pincode: address?.postcode,
        city: address?.city,
        state: address?.state,
        location: address?.formatted_address,
      },
    };
    payload["sourceId"] = address?.branchId || null;
    return payload;
  }

  const isLocalizedSearchEnabledForThisServiceType = !GLOBAL_SEARCH_SERVICE_TYPE?.includes(serviceType);

  return (
    <Box>
      <Flex justify={"space-between"}>
        <Flex align={"center"}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={1} color={"primary.600"}>
            <Icon icon={icon} fontSize={20} />
          </Flex>
          <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
            {title}
          </Heading>
        </Flex>
      </Flex>
      <PlacePicker defaultValue={defaultValue} onSelect={e => onSelectHandler(e)} isLocalizationEnabled={isLocalizedSearchEnabledForThisServiceType} />
    </Box>
  );
}
