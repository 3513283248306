import { Box, Flex, Heading, InputGroup, Stack, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import useOrderAction from "@store/action-hook/useOrderAction";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useState } from "react";
import DatePicker from "react-datepicker";

export default function DateRangePickupDropSection() {
  const { __updateServiceLocationDuration } = useOrderAction();
  const ddb = debounce(__updateServiceLocationDuration, 100);

  const [duration, setDuration] = useState({
    from: null,
    to: null,
  });

  const onFromChange = (value: any) => {
    setDuration(prev => ({ ...prev, from: value }));
    const payload = {
      from: dayjs(value).toDate().getTime(),
      to: dayjs(duration.to).toDate().getTime(),
    };
    ddb(payload);
  };

  const onToChange = (value: any) => {
    setDuration(prev => ({ ...prev, to: value }));
    const payload = {
      from: dayjs(duration.from).toDate().getTime(),
      to: dayjs(value).toDate().getTime(),
    };
    ddb(payload);
  };

  return (
    <Stack p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4} pt={0}>
      <Box mt={4}>
        <Flex align={"center"} justify={"flex-start"} mb={2} ml={2}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={2} color={"primary.600"} ml={-1.5} bg={"purple.100"}>
            <Icon icon={"uim:calender"} fontSize={20} />
          </Flex>
          <Box>
            <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
              From
            </Heading>
            <Text fontSize={"xs"}>Please select From Date and Time</Text>
          </Box>
        </Flex>
        <InputGroup size="sm" w={"full"}>
          <DatePicker popperPlacement="top" minDate={new Date()} selected={duration?.from} onChange={onFromChange} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className="width-full" />
        </InputGroup>
      </Box>
      <Box mt={4}>
        <Flex align={"center"} justify={"flex-start"} mb={2} ml={2}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={2} color={"primary.600"} ml={-1.5} bg={"purple.100"}>
            <Icon icon={"uim:calender"} fontSize={20} />
          </Flex>
          <Box>
            <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
              To
            </Heading>
            <Text fontSize={"xs"}>Please select To Date and Time</Text>
          </Box>
        </Flex>
        <InputGroup size="sm" zIndex={"popover"}>
          <DatePicker minDate={new Date()} selected={duration?.to} onChange={onToChange} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" className="width-full" />
        </InputGroup>
      </Box>
    </Stack>
  );
}
