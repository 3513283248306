import { useGetAllCaseForPilot } from "../../hooks/api/useCaseApi";
import { ICaseResponseObject, IMapData } from "../../config/types";
import {
  Box,
  Text,
  Flex,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Divider,
  Card,
  Tab,
  Stack,
  Badge,
  Tabs,
  TabList,
  useColorModeValue,
  TabPanels,
  TabPanel,
  TagLeftIcon,
  TagRightIcon,
} from "@chakra-ui/react";
import { TextMaker, convertPrice, getTimeDiff } from "../../utils";
import { Icon } from "@iconify/react";
import { getProperty } from "dot-prop";
import {
  AMBULANCE_TYPE_COLORS,
  AMBULANCE_TYPE_ICON,
  COLOR_PLATE_FOR_DRIVER,
  MAP_MARKER_THEME,
} from "../../config/constant";
import { CaseStatusRender } from "../table/CaseListingTableComponent";
import dayjs from "dayjs";
import NetworkWrapper from "../wrapper/NetworkWrapper";
import { useNavigate } from "react-router-dom";
import { RightIcon } from "../../pages/dashboard/personnel-list/PersonnelList";

export default function AmbulanceDetails(props: IMapData) {
  const navigate = useNavigate();
  const { rest, data } = useGetAllCaseForPilot({ pilotId: props?.pilot_id });
  const {
    pilot: { availability, reason },
  } = props;

  const lastCase = data[0];

  const STATUS = availability
    ? "ONLINE"
    : reason === "ON_TRIP"
    ? "ON_TRIP"
    : "OFFLINE";
  const { label, color, background } = MAP_MARKER_THEME[STATUS];

  return (
    <NetworkWrapper status={rest}>
      {" "}
      {lastCase ? (
        <Box>
          <Flex py={2} align={"center"} justify={"space-between"}>
            <Tag
              size="sm"
              colorScheme="blue"
              variant={"outline"}
              opacity={0.6}
              borderRadius="full"
            >
              <TagLabel fontWeight={"semibold"}>
                {getProperty(lastCase, "fleetTypeRequested")
                  ? dayjs
                      .unix((getProperty(lastCase, "createdAt") || 1000) / 1000)
                      .format("DD MMM YYYY hh:mm A")
                  : "-"}
              </TagLabel>
            </Tag>
            <Tag
              borderRadius={"full"}
              fontWeight={"bold"}
              colorScheme={color}
              bg={background}
              fontSize={"2xs"}
            >
              <Box
                h={2.5}
                w={2.5}
                borderRadius={"full"}
                bg={color}
                mr={1.5}
              ></Box>
              <TagLabel>{label}</TagLabel>
            </Tag>
          </Flex>
          <Divider mt={1} mb={5} />
          <Flex justify={"space-between"} align={"center"}>
            <Box>
              <Text
                fontSize={"2xs"}
                fontWeight={"normal"}
                color="blackAlpha.600"
              >
                Case ID
              </Text>
              <Tag
                colorScheme="primary"
                variant={"null"}
                pl={0}
                _hover={{ cursor: "pointer", shadow: "md" }}
                onClick={() =>
                  navigate(`/case/${getProperty(lastCase, "orderId")}`)
                }
              >
                <TagLabel>
                  <Text fontWeight={"semibold"} fontSize={"xs"}>
                    {getProperty(lastCase, "orderId")}
                  </Text>
                </TagLabel>
                <TagRightIcon as={RightIcon} />
              </Tag>
              {/* <Text
                fontSize={"sm"}
                fontWeight={"semibold"}
                color="blackAlpha.900"
              >
                {getProperty(lastCase, "orderId")}
              </Text> */}
            </Box>
            <Box>
              <CaseStatusRender
                status={(lastCase?.orderStatus ?? "NA").toUpperCase()}
                variant={"outline"}
              />
              <Text fontSize={"2xs"} color="green">
                {/* {getTimeDiff(lastCase && lastCase.updatedAt).replace("Since", "") + " ago"} */}
              </Text>
            </Box>
          </Flex>
          <Divider mt={2} mb={2} />
          <Box>
            <Flex justify={"space-between"} align={"center"}>
              <Box>
                <Text fontSize={"2xs"} fontWeight={"thin"}>
                  Ambulance ID:
                </Text>
                <Text fontSize={"xs"} fontWeight={"semibold"}>
                  {getProperty(
                    lastCase,
                    "assignmentDetails.fleetDetails.ambId"
                  )}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"2xs"} fontWeight={"thin"}>
                  Ambulance Number:
                </Text>
                <Text fontSize={"xs"} fontWeight={"semibold"}>
                  {getProperty(
                    lastCase,
                    "assignmentDetails.fleetDetails.ambNumberPlate"
                  )}
                </Text>
              </Box>
            </Flex>
            <Flex justify={"space-between"} align={"center"} mt={2}>
              <Box>
                <Text fontSize={"2xs"} fontWeight={"thin"}>
                  Fare Agreed:
                </Text>
                <Text fontSize={"xs"} fontWeight={"semibold"}>
                  ₹ {convertPrice(getProperty(lastCase, "fareAgreed") || 0)}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"2xs"} fontWeight={"thin"}>
                  Ambulance Type:
                </Text>
                <Text fontSize={"xs"} fontWeight={"semibold"}>
                  {getProperty(lastCase, "fleetTypeRequested")}
                </Text>
              </Box>
            </Flex>
            <Box mt={2}>
              <Text fontSize={"2xs"} fontWeight={"thin"}>
                Ownership:
              </Text>
              <Text fontSize={"xs"} fontWeight={"semibold"}>
                {getProperty(props, "branch_name")}
              </Text>
            </Box>
            <Divider mt={2} mb={2} />
            <Box>
              <Text fontWeight={"semibold"} fontSize={"xs"}>
                Red Squad
              </Text>
              <Wrap>
                <WrapItem w={"full"}>
                  <AmbulancePersonCard
                    icon="healthicons:truck-driver"
                    name={getProperty(
                      lastCase,
                      "assignmentDetails.pilotDetails.meta.userName"
                    )}
                    description={getProperty(
                      lastCase,
                      "assignmentDetails.pilotDetails.meta.mobile"
                    )}
                    badgeName="Driver"
                  />
                </WrapItem>
                <WrapItem w={"full"}>
                  <AmbulancePersonCard
                    theme="WARNING"
                    icon="healthicons:doctor-male"
                    name={getProperty(
                      lastCase,
                      "assignmentDetails.paramedicDetails.meta.userName"
                    )}
                    description={getProperty(
                      lastCase,
                      "assignmentDetails.paramedicDetails.meta.mobile"
                    )}
                    badgeName="Paramedic"
                  />
                </WrapItem>
              </Wrap>
            </Box>
            <Divider mt={2} mb={2} />
            <Box>
              <Text fontWeight={"semibold"} fontSize={"xs"}>
                Case Details
              </Text>
              <Box mt={3}>
                <CustomTabs {...lastCase} />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Text fontSize={"sm"} fontWeight={"semibold"}>
          No Data found
        </Text>
      )}
    </NetworkWrapper>
  );
}

export const AmbulanceRender = ({ type }: { type?: string }) => {
  const defaultColor = "slate.800";
  const transformText = (type || "unknown")?.toString().toUpperCase();

  const tagColorScheme = AMBULANCE_TYPE_COLORS[transformText] || defaultColor;
  const tagIcon = AMBULANCE_TYPE_ICON[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Icon icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {transformText}
      </TagLabel>
    </Tag>
  );
};

interface IAmbulancePersonCard {
  badgeName?: string;
  icon?: string;
  name?: string;
  description?: string;
  theme?: "SUCCESS" | "WARNING";
}
export const AmbulancePersonCard = ({
  badgeName,
  icon,
  name,
  description,
  theme = "SUCCESS",
}: IAmbulancePersonCard) => {
  const { bg, color, colorSchema } = COLOR_PLATE_FOR_DRIVER[theme];

  if (!name) return <></>;

  return (
    <Card w={"full"} p={3} mt={2} mb={1} overflow={"hidden"}>
      <Flex justify={"space-between"} align={"center"}>
        <Flex>
          <Box mr={2} bg={bg} w={38} h="38" borderRadius={"md"} color={color}>
            {icon && <Icon icon={icon} fontSize={40} />}
          </Box>
          <Box>
            {name && (
              <Text
                fontSize={"xs"}
                fontWeight={"semibold"}
                textTransform={"uppercase"}
                color={"blackAlpha.900"}
                letterSpacing={"wide"}
              >
                {name}
              </Text>
            )}
            {description && (
              <Text
                fontSize={"xs"}
                fontWeight={"medium"}
                color={"blackAlpha.700"}
              >
                {description}
              </Text>
            )}
          </Box>
        </Flex>
        {badgeName && (
          <Badge
            variant="subtle"
            colorScheme={colorSchema}
            h="4"
            pos={"absolute"}
            right={0}
            top={0}
            fontSize={"2xs"}
          >
            {badgeName}
          </Badge>
        )}
      </Flex>
    </Card>
  );
};

function CustomTabs(props: ICaseResponseObject) {
  const { color, bg, colorSchema } = COLOR_PLATE_FOR_DRIVER["GREY"];

  const cardSlateBg = useColorModeValue("white", "slate.800");

  const getDistance = (distance: number) => {
    if (!distance) {
      return "-";
    }
    const factor = distance / 1000;
    factor > 1 ? factor.toFixed(1) : factor.toFixed(3);

    return factor ? `${factor}Km` : "-";
  };

  return (
    <Tabs variant="soft-rounded" colorScheme={colorSchema} size={"xs"}>
      <TabList
        bg={"white"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tab fontSize={"xs"} h={6} px={1} borderRadius={"md"}>
          <Icon icon={"fluent:location-12-filled"} fontSize={15} />
          <Box ml={1}>Location</Box>
        </Tab>
        <Tab fontSize={"xs"} h={6} px={2} borderRadius={"md"}>
          <Icon icon={"mdi:patient"} fontSize={15} />
          <Box ml={1}> Patient</Box>
        </Tab>
        <Tab fontSize={"xs"} h={6} px={1} borderRadius={"md"}>
          <Icon icon={"tabler:direction-sign-filled"} fontSize={15} />
          <Box ml={1}>Instructions</Box>
        </Tab>
      </TabList>
      <TabPanels bg={cardSlateBg} borderWidth={0}>
        <TabPanel>
          <Stack>
            <ItemRender
              name={"Pick-Up Location"}
              description={TextMaker(getProperty(props, "waypoints[0]"))}
            />
            <ItemRender
              name={"Drop Location"}
              description={TextMaker(getProperty(props, "waypoints[1]"))}
            />
            <ItemRender
              name={"Distance"}
              description={getDistance(getProperty(props, "distance") || 0)}
            />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack>
            <ItemRender
              name={"Patient Name"}
              description={getProperty(props, "requestedFor.name") || "-"}
            />
            <ItemRender
              name={"Patient Contact Number"}
              description={getProperty(props, "requestedFor.mobile") || "-"}
            />
            <ItemRender
              name={"Medical Reason"}
              description={(
                getProperty(props, "requestedFor.medicalIssue.reason") || ["-"]
              )?.join(",")}
            />
            <ItemRender
              name={"Patient Weight"}
              description={getProperty(props, "requestedFor.Weight") || "-"}
            />
            <ItemRender
              name={"Patient Age"}
              description={getProperty(props, "requestedFor.age") || "-"}
            />
            <ItemRender
              name={"Patient Gender"}
              description={getProperty(props, "requestedFor.gender") || "-"}
            />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack>
            <Text>-</Text>
            // TODO: need to implement this
            {/* {get(savedTriage, "data.instructions", []).filter((data: any) => data.included).map((sym: any) => (
                      <Box key={sym.symptom} mb="4" pl="4">
                        <FlexBox>
                          <Column width="50%">
                            <Text fontSize="sm">{sym.instruction}</Text>
                          </Column>
                        </FlexBox>
                      </Box>
                    ))} */}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

const ItemRender = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  return (
    <Box my={2}>
      <Text
        fontSize={"2xs"}
        fontWeight={"normal"}
        mb={1}
        color={"blackAlpha.800"}
        letterSpacing={"wide"}
      >
        {name}
      </Text>
      <Text fontSize={"xs"} fontWeight={"semibold"} color={"blackAlpha.800"}>
        {description}
      </Text>
    </Box>
  );
};
