import { Box, Checkbox, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

import { Icon } from "@iconify/react";
import { IAddress, ILabelValue } from "../../config/types";
import PlacePicker from "./PlacePicker";
import { useSourceData } from "@hooks/api/useAppApi";
import { useRedStore } from "@store/store";
import { GLOBAL_SEARCH_SERVICE_TYPE } from "@config/constant";
import useOrderAction from "@store/action-hook/useOrderAction";
import { createCaseTogglePartnerSearch } from "@config/event";
import { useParams } from "react-router-dom";

interface IPickupDropSection {
  title: string;
  icon: string;
  type: "pickup" | "dropoff";
  onSelect: (address: any) => void;
  sequence: number;
  value?: any;
  withPlaceApi?: boolean;
  defaultValue: any;
  isDefaultPartnerSearchSelect: boolean;
  serviceType: string;
}

const MyOption = (props: any) => {
  const { innerProps, innerRef } = props;
  const { value } = props.data;
  return (
    <Box ref={innerRef} {...innerProps} bg={"white"} shadow={"sm"} my={2} mx={2} p={2} borderRadius={"sm"}>
      <Text fontWeight={"semibold"} fontSize={"sm"} color={"green.500"}>
        {props.children}
      </Text>
      <Text fontSize={"xs"} color={"green.700"}>
        {value.formatted_address}
      </Text>
    </Box>
  );
};
// This is modify the data for react-select
const prepData = (data: any): any => {
  const newData =
    data &&
    Object.values(data)
      ?.map((itm: any) =>
        itm?.onlyForLocation
          ? {
              label: `${itm.name}`,
              value: itm,
              cityCode: itm.cityCode,
            }
          : null
      )
      .filter(itm => !!itm);

  return newData || [];
};

export default function PickupDropSection({ title, icon, type, onSelect, sequence, value, defaultValue, isDefaultPartnerSearchSelect, serviceType }: IPickupDropSection) {
  const { id } = useParams();
  const { setSearchKey, filteredOptions, ...rest } = useSourceData({ prepData });
  const data = useRedStore(state => state.source);
  const [isPartnerSearch, setIsPartnerSearch] = useState(isDefaultPartnerSearchSelect);
  const { __updateLocationType } = useOrderAction();

  useEffect(() => {
    setIsPartnerSearch(isDefaultPartnerSearchSelect);
  }, [isDefaultPartnerSearchSelect]);

  const findData: any = (sourceId: string) => {
    const partner = data && Object.values(data).find((itm: any) => itm.branchId === sourceId);
    return partner ? { label: `${partner.name}`, value: partner } : null;
  };

  const onSelectHandler = (addressObj: ILabelValue) => {
    __updateLocationType({ type, address: addressObj });
    const payload = processAddress(addressObj.value);
    onSelect(payload);
  };

  function processAddress(address: IAddress) {
    const geo = {
      lat: address?.geometry?.location?.lat,
      long: address?.geometry?.location.lng,
    };

    const payload: any = {
      geo: geo,
      city: address?.city,
      type,
      sequence,
      address: {
        pincode: address?.postcode,
        name: address?.name,
        city: address?.city,
        state: address?.state,
        location: address?.formatted_address,
      },
    };
    payload["sourceId"] = address?.branchId || null;
    return payload;
  }

  const isLocalizedSearchEnabledForThisServiceType = !GLOBAL_SEARCH_SERVICE_TYPE.includes(serviceType);

  const handleToggle = (e: any) => {
    const isEnabled = e.target.checked;
    createCaseTogglePartnerSearch({ orderId: id, isEnabled, type });
    setIsPartnerSearch(isEnabled);
  };

  return (
    <Box>
      <Flex justify={"space-between"}>
        <Flex align={"center"}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={1} color={"primary.600"}>
            <Icon icon={icon} fontSize={20} />
          </Flex>
          <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
            {title}
          </Heading>
        </Flex>
        <Flex align={"center"}>
          <Text fontSize={"xs"} mr={2}>
            Partner search
          </Text>
          <Checkbox colorScheme="green" onChange={handleToggle} isChecked={isPartnerSearch} />
        </Flex>
      </Flex>
      {isPartnerSearch ? (
        <>
          <ReactSelect
            className="basic-single"
            isLoading={rest.isLoading}
            filterOption={null}
            onInputChange={setSearchKey}
            classNamePrefix="select"
            value={findData(value)}
            isSearchable={true}
            name="color"
            options={filteredOptions}
            components={{ Option: MyOption }}
            onChange={(e: any) => onSelectHandler(e)}
            styles={{
              control: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: 14,
                fontWeight: 700,
                minHeight: "35px",
                height: "35px",
              }),
              valueContainer: provided => ({
                ...provided,
                height: "35px",
                padding: "0 6px",
              }),
              input: provided => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "35px",
              }),
              option: (base: any) => ({
                ...base,
                zIndex: 9999,
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "0.02em",
                textTransform: "capitalize",
                backgroundColor: "white",
                color: "black",
              }),
            }}
          />
          {defaultValue?.address?.location && (
            <Text fontSize={"sm"} m={2}>
              {defaultValue?.address?.location}
            </Text>
          )}
        </>
      ) : (
        <PlacePicker isLocalizationEnabled={isLocalizedSearchEnabledForThisServiceType} defaultValue={defaultValue} onSelect={e => onSelectHandler(e)} />
      )}
    </Box>
  );
}
