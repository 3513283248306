import DropDownSection from "../atom/DropDownSection";
import { useCity } from "../../hooks/api/useMdm";
import { memo, useEffect } from "react";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { createCaseCityChangeTo } from "@config/event";

const CityDropDown = memo(() => {
  const { data, ...rest }: any = useCity();

  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateCity } = useOrderAction();

  const onSelect = ({ value }: any) => {
    console.log("value: ", value);
    createCaseCityChangeTo({ city: value, orderId: order?.orderId });
    __updateCity(value);
  };

  const prepData = (data: any) => {
    const newData =
      data
        ?.filter((itm: any) => !!itm?.active)
        .map((itm: any) => ({
          label: `${itm.name} - ${itm.code}`,
          value: itm.code,
        })) || [];
    return newData;
  };

  return (
    <DropDownSection
      defaultValue={order?.city}
      value={localOrderChanges.city}
      icon="mdi:home-city-outline"
      title="City"
      options={prepData(data?.data?.data)}
      onSelect={onSelect}
      maxWidth={200}
      // TODO: need to show tooltip when source city and City is not same
      // tollTip="some value"
      isLoading={rest.isFetching}
    />
  );
});

export default CityDropDown;
