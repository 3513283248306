import { Box, Flex, FormControl, Heading, Input, InputProps } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";

interface IInputBox extends InputProps {
  label: string;
}

export default function InputBox({ label, ...rest }: IInputBox) {
  return (
    <Box mt={5}>
      <FormControl id="otp">
        <Flex align={"center"} mt={1}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={1} color={"primary.600"}>
            <Icon icon={"fluent:patient-24-filled"} fontSize={20} />
          </Flex>
          <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
            {label}
          </Heading>
        </Flex>
        <Input placeholder="Contact Name" size={"sm"} py={4} fontWeight={"normal"} {...rest} />
      </FormControl>
    </Box>
  );
}
