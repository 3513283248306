import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { IMapData } from "../../config/types";
import alsAmb from "/assets/ambulances/als-logo.svg";
import blsAmb from "/assets/ambulances/bls-logo.svg";
import { getProperty } from "dot-prop";
import {
  Box,
  Card,
  Badge,
  Stack,
  Text,
  Flex,
  useDisclosure,
  ModalOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Divider,
  useMediaQuery,
  Center,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
} from "@chakra-ui/react";
import { MAP_MARKER_THEME } from "../../config/constant";
import AmbulanceDetails from "./AmbulanceDetails";

interface IMapMarker extends IMapData {
  isActive: boolean;
  onMarkerSelect: (param?: any) => void;
  isReadMode: boolean;
}

// Marker Icon
const markerIconFactory = (fleet_type: string) => ({
  url: fleet_type === "bls" ? blsAmb : alsAmb,
  scaledSize: new google.maps.Size(50, 50),
});

export default function MapMarker(props: IMapMarker) {
  const {
    location,
    pilot,
    onMarkerSelect,
    fleet_type,
    isReadMode = false,
  } = props;

  const markerPopupRef: any = useRef(null);
  const markerRef: any = useRef(null);
  const [hoverOn, setHoverOn] = React.useState(false);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current?.setIcon(markerIconFactory(fleet_type));
    }
  }, [fleet_type]);

  if (!location) {
    return <></>;
  }

  // if (!isReadMode && !pilot) {
  //   return <></>;
  // }

  const handleOnLoad = (markerInstance: any) => {
    markerRef.current = markerInstance;
  };

  const onMouseEnter = () => {
    if (isReadMode) {
      return;
    }
    setHoverOn(true);
  };

  const onMouseExit = () => {
    if (isReadMode) {
      return;
    }
    setHoverOn(false);
  };

  const onMarkerClick = () => {
    if (isReadMode) {
      return;
    }
    onMarkerSelect(props);
    markerPopupRef.current && markerPopupRef?.current?.updateVisibility("open");
  };

  return (
    <MarkerF
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseExit}
      position={location}
      onClick={onMarkerClick}
      onLoad={handleOnLoad}
      icon={markerIconFactory(fleet_type)}
    >
      {hoverOn && (
        <InfoWindowF>
          <MarkerHoverBody {...props} />
        </InfoWindowF>
      )}
      <SelectedMark {...props} ref={markerPopupRef} />
    </MarkerF>
  );
}

const MarkerHoverBody = (props: IMapData) => {
  const {
    pilot: { reason, availability },
    fleet_type,
  } = props;

  const STATUS = availability
    ? "ONLINE"
    : reason === "ON_TRIP"
    ? "ON_TRIP"
    : "OFFLINE";
  const { label, color, background } = MAP_MARKER_THEME[STATUS];

  const keys = [
    {
      label: "Vehicle No",
      key: "registration_number",
    },
    {
      label: "Pilot ID",
      key: "pilot_id",
    },
    {
      label: "Driver",
      key: "pilot_name",
    },
    {
      label: "Paramedic",
      key: "paramedic_name",
    },
    {
      label: STATUS === "ON_TRIP" ? "Case ID" : "Last Case ID",
      key: "pilot.meta.assignmentDetails.clientRequestId",
    },
    {
      label: "Ownership",
      key: "branch_name",
    },
  ];

  return (
    <Box>
      <Card p={2}>
        <Flex
          justify={"space-between"}
          align={"center"}
          bg={background}
          p={2}
          borderRadius={"md"}
        >
          <Text fontWeight={"bold"} colorScheme={color} fontSize={"2xs"}>
            {label}
          </Text>
          <Badge px={2} py={1} colorScheme={color}>
            {fleet_type?.toUpperCase()}
          </Badge>
        </Flex>
        <Stack spacing="2" mt={5}>
          {keys.map((path, index) => (
            <Flex key={index} justify={"space-between"} align={"center"}>
              <Text
                fontSize="2xs"
                textTransform="uppercase"
                mr={2}
                fontWeight={"medium"}
                opacity={0.5}
              >
                {path.label}
              </Text>
              <Text fontSize="2xs" fontWeight={"semibold"}>
                {getProperty(props, path.key) ?? "-"}
              </Text>
            </Flex>
          ))}
        </Stack>
      </Card>
    </Box>
  );
};

const SelectedMark = forwardRef((props: IMapMarker, ref: any) => {
  const [isMobileView] = useMediaQuery("(max-width: 768px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    updateVisibility(mode: string) {
      if (mode === "open") {
        onOpen();
      } else {
        onCloseHandle();
      }
    },
  }));

  // useEffect(() => {
  //   onOpen();
  // }, []);

  const onCloseHandle = () => {
    props.onMarkerSelect(null);
    onClose();
  };

  return (
    <>
      {!isMobileView ? (
        <Modal
          isOpen={isOpen}
          onClose={onCloseHandle}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent mx={5}>
            <ModalCloseButton />
            <ModalBody>
              <AmbulanceDetails {...props} />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer placement={"bottom"} onClose={onCloseHandle} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent h={"90vh"} borderTopRadius={"md"}>
            {/* <DrawerHeader borderBottomWidth="1px" fontSize={"md"}>
              Case Details
            </DrawerHeader> */}
            <DrawerBody>
              <AmbulanceDetails {...props} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
});
