import React, { useEffect } from "react";
import PickupDropSection from "./PickupDropSection";
import { Box, Center, Stack } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import PickupDropInfoSection from "./PickupDropInfoSection";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { usePricingHook } from "../../hooks/api/useCaseApi";
import { WaypointsEntity } from "../../config/types";
import { getProperty } from "dot-prop";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useOrder } from "@hooks/api/useSave";
import { debounce } from "lodash";
import GoogleAddressPickup from "@components/molecules/GoogleAddressPickup";

export default function ServiceLocation() {
  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const pricing: any = useRedStore(state => state.price);
  const { syncWaypoint } = useOrder();

  const { __updateServiceLocation } = useOrderAction();

  const { execute, isLoading } = usePricingHook();
  const dbExecute = debounce(execute, 300);

  const onPickupSelect = (address: any) => {
    __updateServiceLocation(address);
  };

  const servicePoint: any = (localOrderChanges?.serviceLocation && localOrderChanges?.serviceLocation[0]) || (order?.serviceLocation && order?.serviceLocation[0]);

  return (
    <Stack p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
      <GoogleAddressPickup
        defaultValue={servicePoint}
        withPlaceApi={true}
        icon="bxs:map-pin"
        title="Service Location"
        type="pickup"
        onSelect={onPickupSelect}
        sequence={0}
        value={servicePoint?.sourceId}
        isDefaultPartnerSearchSelect={false}
        serviceType={localOrderChanges?.serviceType}
      />
    </Stack>
  );
}
