import { Box, Flex, HStack, Heading, Input, InputGroup, InputRightAddon, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import React, { useState } from "react";

import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { debounce } from "lodash";

type Props = {};

export default function HightInput({}: Props) {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const { __updatePatientHeight } = useOrderAction();

  const [heightChanges, setHeightChanges] = useState({
    feet: currentCase?.requestedFor?.height?.feet || 0,
    inches: currentCase?.requestedFor?.height?.inches || 0,
  });

  const onFeetChange = (e: any) => {
    const _feet = parseInt(e.target.value);
    const feet = isNaN(_feet) ? 0 : _feet;
    const obj = { ...heightChanges, feet };
    setHeightChanges(prev => ({ ...prev, feet }));
    updateStateCb(obj);
  };

  const onInchChange = (e: any) => {
    const _inch = parseInt(e.target.value);
    const inches = isNaN(_inch) ? 0 : _inch;
    const obj = { ...heightChanges, _inch };
    setHeightChanges(prev => ({ ...prev, inches }));
    updateStateCb(obj);
  };

  const updateStateCb = debounce(__updatePatientHeight, 100);

  return (
    <Box mt={4}>
      <Flex align={"center"} justify={"flex-start"}>
        <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={1} color={"primary.600"} ml={-1.5}>
          <Icon icon={"fluent:book-number-24-filled"} fontSize={22} />
        </Flex>
        <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
          Height
        </Heading>
      </Flex>
      <Flex w={44} gap={2}>
        <NumberInput pos={"relative"}>
          <NumberInputField h={9} placeholder="Feet" px={2} w={20} value={heightChanges.feet} onChange={onFeetChange} fontSize={"sm"} pr={10} />
          <Flex pos={"absolute"} right={0} top={0} bg={"gray.200"} h={"full"} px={3} justifyContent={"center"} align={"center"} borderRightRadius={"base"}>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"black"}>
              Ft
            </Text>
          </Flex>
        </NumberInput>
        <NumberInput pos={"relative"}>
          <NumberInputField h={9} placeholder="Inch" px={2} w={24} value={heightChanges.inches} onChange={onInchChange} fontSize={"sm"} pr={10} />
          <Flex pos={"absolute"} right={0} top={0} bg={"gray.200"} h={"full"} px={3} justifyContent={"center"} align={"center"} borderRightRadius={"sm"}>
            <Text fontWeight={"semibold"} fontSize={"sm"} color={"black"}>
              In
            </Text>
          </Flex>
        </NumberInput>
      </Flex>
    </Box>
  );
}
