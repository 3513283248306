import { TServiceTypes, TTServiceTypes } from "@config/constant";
import React, { useEffect, useState } from "react";
import serviceTypeConfig from "@config/serviceType.config";
import { showErrorToast } from "@utils";
import { useRedStore } from "@store/store";

type Props = {
  serviceType: TTServiceTypes;
};

const ALL_ISLAND = {
  DISPATCH_ISLAND: 0,
  PICKUP_DROP_ISLAND: 0,
  LAMA_DAMA_ISLAND: 0,
  PATIENT_DETAILS_ISLAND: 0,
  COVID_SWITCH_ISLAND: 0,
  AMBULANCE_SELECTION_ISLAND: 0,
  ADDONS_ISLAND: 0,
  PRICE_SECTION_ISLAND: 0,
  CUSTOM_FARE_AGREED: 0,
};

type TAllIslandType = keyof typeof ALL_ISLAND;

export default function useTemplate() {
  const [alowIslands, setIslands] = useState<any>(ALL_ISLAND);
  const serviceType: any = useRedStore(state => state.order.serviceType);

  const mountServiceMap = (_serviceType: TTServiceTypes) => {
    try {
      const key: any = _serviceType;
      setIslands(serviceTypeConfig[key]);
    } catch (err) {
      showErrorToast("Invalid Service type.");
      setIslands(ALL_ISLAND);
    }
  };

  useEffect(() => {
    if (serviceType) {
      mountServiceMap(serviceType);
    }
  }, [serviceType]);

  return { alowIslands };
}
