import { useRedStore } from "@store/store";
import { debounce, sortBy } from "lodash";

// This is a hook file which is between UI and Store + Network.
/**
 *
 * Case 1: User just update the store
 * Case 2: User update and make the API Call -> API call resolve and update the store
 *
 * So this action hook can do both
 */

const dispatch = (cb: any) => debounce(() => cb(), 50);

export default function useFleetAction() {
  const _updateEligibleVehicles = useRedStore(state => state._updateEligibleVehicles);
  const _updateInEligibleVehicles = useRedStore(state => state._updateInEligibleVehicles);
  const _updateSearchKey = useRedStore(state => state._updateSearchKey);

  const __updateOnePVehicles = (list: any) => {
    _updateEligibleVehicles(sortBy(list, ["pickupDistance"]));
  };

  const __updateNearbyVehicles = (list: any) => {
    _updateInEligibleVehicles(sortBy(list, ["distance"]));
  };

  const __updateSearchVehicles = (list: any) => {
    dispatch(_updateInEligibleVehicles(list));
  };

  const __updateSearchKey = (key: string) => {
    _updateSearchKey(key?.toUpperCase());
  };

  return { __updateOnePVehicles, __updateNearbyVehicles, __updateSearchVehicles, __updateSearchKey };
}
