import { useEffect, useRef, useState } from "react";
import { parseGooglePlaceAddress } from "../../utils";
import { useGoogleMaps } from "@components/molecules/GoogleMapsProvider";

type Props = {
  onAddressSelect: (obj?: any) => void;
  isLocalizationEnabled?: boolean;
};

export default function usePlace({ onAddressSelect, isLocalizationEnabled }: Props) {
  const { isLoaded } = useGoogleMaps();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [payload, setPayload] = useState<any>({});

  const options: google.maps.places.AutocompleteOptions = {
    fields: ["address_components", "geometry", "icon", "name", "formatted_address"],
    types: ["establishment"],
  };

  if (isLocalizationEnabled) {
    options.componentRestrictions = { country: "in" };
  }

  useEffect(() => {
    if (isLoaded && !!window.google && !!window.google.maps && !!window.google.maps.places) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current as HTMLInputElement, options);
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current?.getPlace();

        const formattedAddress = place?.formatted_address || "";
        const name = place?.name || "";
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();

        const address = parseGooglePlaceAddress(place);
        const responsePayload = {
          formatted_address: `${name} ${formattedAddress}`,
          name: place?.name,
          geometry: {
            location: {
              lat,
              lng,
            },
          },
          ...address,
        };
        setPayload(responsePayload);
        onAddressSelect && onAddressSelect(responsePayload);
      });
    }
  }, [isLoaded]);

  return [inputRef, payload];
}
