import { useQuery } from "react-query";
import { getLatestAppVersion, getSourceData } from "../../api/service/app";
import { getAllSource } from "api/service/mdm";
import { useRedStore } from "@store/store";
import { getAllNearbyVehicle, getDispatchEntities, getEntitiesPrice } from "api/service/daas";
import { objToQuery, objectToQueryStringOmitEmpty, showErrorToast } from "@utils";
import { IOnePVehicleInterface } from "@config/types";
import { useActionData } from "react-router-dom";
import useOrderAction from "@store/action-hook/useOrderAction";
import { AMBULANCE_MAPPER_KEY, DEFAULT_VEHICLE_SEARCH_RADIUS, DISPATCH_VEHICLE_KEY, NEARBY_SEARCH_KEY, PRICING_KEY } from "@config/constant";
import { useEffect } from "react";
import { INITIAL_VEHICLE_NEARBY_STATE } from "@store/constant.store";
import useFleetAction from "@store/action-hook/useFleetAction";
import { getProperty } from "dot-prop";

const useGetOnePVehicle = ({ orderId }: { orderId: string }) => {
  const { __updateOnePVehicles } = useFleetAction();
  const payload = {
    searchRadius: DEFAULT_VEHICLE_SEARCH_RADIUS,
    orderId,
  };

  const handleOnSuccess = (data: any) => {
    try {
      const response = data?.data?.data;
      if (response?.ONE_P && Array.isArray(response?.ONE_P)) {
        __updateOnePVehicles(response?.ONE_P);
      }
    } catch (err) {
      throw "Unable to process ONE_P vehicle data.";
      return;
    }
  };

  return useQuery([DISPATCH_VEHICLE_KEY], () => getDispatchEntities(payload), {
    cacheTime: 1000 * 30, // 0 seconds
    // enabled: false,
    enabled: Object.values(payload).every(Boolean),
    onSuccess: handleOnSuccess,
    onError: (error: any) => {
      console.log("error: ", error);
      showErrorToast("Failed to fetch vehicle info.");
    },
    // refetchOnMount: true
  });
};

const useOnePAmbulance = () => {
  const currentChanges = useRedStore(state => state.localOrderChanges);
  const { __updateNearbyVehicle } = useOrderAction();

  const payload = {
    ambulanceType: currentChanges?._selectedVehicleType?.value,
    searchRadius: DEFAULT_VEHICLE_SEARCH_RADIUS,
    orderId: currentChanges?.orderId,
  };

  const pricingPayload = {
    vehicle: currentChanges?._selectedVehicleType?.value,
    orderId: currentChanges?.orderId,
  };

  const calculateEntity = (data: IOnePVehicleInterface[], initialState: any) => {
    const metaGenFn = (acc: any, vehicle: IOnePVehicleInterface) => {
      const { ambulanceType, ambulanceSpec, pickupEta } = vehicle;

      const subtype = AMBULANCE_MAPPER_KEY[ambulanceType || ambulanceSpec];

      if (!acc[subtype]) {
        acc[subtype] = {
          price: 0,
          eta: 0,
          quantity: 0,
        };
      } else {
        if (subtype !== "als" && subtype?.includes("als")) {
          acc["als"].quantity = acc["als"].quantity + 1;
          if (acc[subtype].eta) {
            if (!acc["als"].eta) {
              acc["als"].eta = acc[subtype].eta;
            } else {
              acc["als"].eta = acc[subtype].eta < acc["als"].eta ? acc[subtype].eta : acc["als"].eta;
            }
          }
        }

        if (subtype !== "bls" && (subtype?.includes("bls") || subtype?.includes("als"))) {
          acc["bls"].quantity = acc["bls"].quantity + 1;
          if (acc[subtype].eta) {
            if (!acc["bls"].eta) {
              acc["bls"].eta = acc[subtype].eta;
            } else {
              acc["bls"].eta = acc[subtype].eta < acc["bls"].eta ? acc[subtype].eta : acc["bls"].eta;
            }
          }
        }

        if (subtype !== "als" || subtype !== "bls") {
          if (pickupEta) {
            if (!acc[subtype].eta) {
              acc[subtype].eta = pickupEta;
            } else {
              acc[subtype].eta = acc[subtype].eta < pickupEta ? acc[subtype].eta : pickupEta;
            }
          }
        }

        acc[subtype].quantity = acc[subtype].quantity + 1;
      }
      return acc;
    };

    const payload = data?.reduce(metaGenFn, initialState);

    const val = Object.values(payload)?.reduce((a: any, b: any) => {
      if (b?.eta && b?.eta < a) {
        return b?.eta;
      } else {
        return a;
      }
    }, 34567890);

    payload.bls.eta = val === 34567890 ? 0 : val;

    let gg = 34567890;

    for (const i in payload) {
      if (i?.includes("als")) {
        const val = payload[i];
        if (val?.eta && val?.eta < gg) {
          gg = val?.eta;
        }
      }
    }

    payload.als.eta = gg === 34567890 ? 0 : gg;

    return payload;
  };

  const pricing: any = useQuery([PRICING_KEY], () => getEntitiesPrice(pricingPayload), {
    cacheTime: 1000 * 30, // 0 seconds
    enabled: Object.values(payload).every(Boolean),
    onError: (error: any) => {
      console.log("error: ", error);
      showErrorToast("Failed to fetch vehicle price.");
    },
    // refetchOnMount: true
  });

  const dispatchEntity: any = useQuery([DISPATCH_VEHICLE_KEY], () => getDispatchEntities(payload), {
    cacheTime: 1000 * 30, // 0 seconds
    enabled: Object.values(payload).every(Boolean),
    onError: (error: any) => {
      console.log("error: ", error);
      showErrorToast("Failed to fetch vehicle info.");
    },
    // refetchOnMount: true
  });

  useEffect(() => {
    if (pricing.isFetched && dispatchEntity.isFetched) {
      try {
        const nearbyVehicleObj: any = JSON.parse(JSON.stringify(INITIAL_VEHICLE_NEARBY_STATE));
        const dispatchEntityResponse: any = dispatchEntity?.data?.data?.data;
        const pricingResponse: any = pricing?.data?.data?.data;

        if (pricingResponse?.vehiclePricing) {
          pricingResponse?.vehiclePricing?.forEach((vehicle: any) => {
            const subtype = AMBULANCE_MAPPER_KEY[vehicle?.vehicleType];
            if (subtype) {
              nearbyVehicleObj[subtype].price = vehicle?.price;
            }
          });

          calculateEntity(dispatchEntityResponse?.ONE_P, nearbyVehicleObj);
          __updateNearbyVehicle(nearbyVehicleObj);
        }
      } catch (error) {
        console.log("error: ", error);
        showErrorToast("Failed to parse vehicle info.");
      }
    }
  }, [pricing.isFetching, dispatchEntity.isFetching]);

  return { isFetching: pricing.isFetching || dispatchEntity.isFetching, isLoading: pricing.isLoading || dispatchEntity.isLoading };
};

const useGetNearbyVehicle = () => {
  const { __updateNearbyVehicles } = useFleetAction();
  const order = useRedStore(state => state.order);

  const payload = {
    lat: getProperty(order, "waypoints[0].geo.lat", ""),
    lng: getProperty(order, "waypoints[0].geo.long", ""),
    searchRadius: DEFAULT_VEHICLE_SEARCH_RADIUS,
    city: getProperty(order, "city"),
    sort: "ASC",
    type: "FLEET",
    entityType: "person&entityType=fleet&entityType=PILOT&entityType=entity",
  };

  const handleOnSuccess = (data: any) => {
    try {
      const response = data?.data?.data;
      console.log(data?.data);
      if (response && Array.isArray(response)) {
        __updateNearbyVehicles(response);
      }
    } catch (err) {
      throw "Unable to process ONE_P vehicle data.";
      return;
    }
  };

  return useQuery([NEARBY_SEARCH_KEY], () => getAllNearbyVehicle(objToQuery(payload)), {
    cacheTime: 1000 * 30, // 0 seconds
    // enabled: false,
    enabled: Object.values(payload).every(Boolean),
    onSuccess: handleOnSuccess,
    onError: (error: any) => {
      console.log("error: ", error);
      showErrorToast("Failed to Fetch Nearby Vehicle info.");
    },
    // refetchOnMount: true
  });
};

export { useOnePAmbulance, useGetOnePVehicle, useGetNearbyVehicle };
