import { HM_CAN_DISPATCH_SERVICE_TYPE, PATIENT_INFO_CONSTANT, TServiceTypes, X_DISTANCE } from "@config/constant";
import { useRedStore } from "@store/store";
import { debounce } from "lodash";
import { useDeferredValue, useEffect, useState } from "react";

interface ICaseObserver {
  _isServiceSectionValid: boolean;
  _isDispatchModeValid: boolean;
  _isWaypointValid: boolean;
  _isServiceLocationValid: boolean;
  _isPatientDetailsValid: boolean;
  _isVehicleDetailsValid: boolean;
  _isPatientInfoValid: boolean;
  _isAddonValid: boolean;
  _isReadyToDispatch: boolean;
  _isFromAndToDateFill: boolean;
  isValid: boolean;
  _dispatch_showAssignmentButton: boolean;
  _dispatch_showCollectAdvanceButton: boolean;
  _dispatch_collect_advance_other_service_type: boolean;
  _x_km_dispatch: boolean;
  _dispatch_showScheduleButton: boolean;
}

export default function useCaseObserver() {
  const [accessState, setAccess] = useState<ICaseObserver>({
    _isServiceSectionValid: false,
    _isDispatchModeValid: false,
    _isWaypointValid: false,
    _isServiceLocationValid: false,
    _isPatientDetailsValid: false,
    _isVehicleDetailsValid: false,
    _isAddonValid: false,
    _isReadyToDispatch: false,
    isValid: false,
    _isFromAndToDateFill: false,
    _isPatientInfoValid: false,
    _dispatch_showAssignmentButton: false,
    _dispatch_showCollectAdvanceButton: false,
    _dispatch_collect_advance_other_service_type: false,
    _dispatch_showScheduleButton: false,
    _x_km_dispatch: false,
  });

  const access = useDeferredValue(accessState);

  const acc = debounce(setAccess, 300);
  const observe = (state: any) => {
    let _isAddonSectionValid =
      state.isOverridenPrice ||
      state.billToClient ||
      (!state.selectedAddons?.find((addon: any) => addon.isCustomPrice && addon?.subtypes?.[0]?.id && !addon?.type?.split(" - ")?.[1]) &&
        !state.selectedAddons?.find((addon: any) => addon.isCustomPrice && (addon.price === null || addon.price === undefined || addon.price === "")));
    let _isServiceSectionValid = !!state.serviceType && !!state.city && !!state.attributedTo && !!state.originSource?.source && !!state.department;
    let _isDispatchModeValid = !!state.dispatchType && (state.dispatchType === "NOW" || (state.dispatchType === "SCHEDULED" && !!state.scheduledDdtm));
    let _isWaypointValid = state.waypoints && state.waypoints.length > 1 && _isServiceSectionValid && _isDispatchModeValid && !!state.waypoints[0]?.address && !!state.waypoints[1]?.address;
    let _isServiceLocationValid = state.serviceLocation && state.serviceLocation.length > 0 && _isServiceSectionValid && _isDispatchModeValid && !!state.serviceLocation[0]?.address;
    let _isPatientDetailsValid =
      state?.requestedFor?.medicalIssue?.reason?.length > 0 &&
      !!state.requestedFor.age &&
      state?.requestedFor?.age <= PATIENT_INFO_CONSTANT.AGE.max &&
      !!state.requestedFor.gender?.value &&
      !!state.requestedFor.name;
    let _isVehicleDetailsValid = !!state.entityRequired && state.entityRequired?.length > 0 && !!state.entityRequired[0]?.subtype;
    let _isAddonValid = !!state.dispatchType && state.dispatchType === "SCHEDULED" && _isVehicleDetailsValid;
    let _isPatientInfoValid = !!state.requestedFor.age && state?.requestedFor?.age <= 120 && !!state.requestedFor.gender?.value && !!state.requestedFor.name;
    let _isFromAndToDateFill = !!state.serviceLocationDuration.from && !!state.serviceLocationDuration.to;
    // &&!!state.requestedFor.height?.feet &&
    // (state.requestedFor.height?.inches !== null || state.requestedFor.height?.inches !== undefined);
    let _isReadyToDispatch = false;

    const service: TServiceTypes = state.serviceType;

    // if (state.dispatchType === "SCHEDULED") {
    //   _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid;
    // }

    // switch (service) {
    //   case "STANDARD":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid;
    //     break;

    //   case "ORGAN_TRANSPORTATION":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isVehicleDetailsValid && !!state.fareAgreed;
    //     break;
    //   case "AIR_AMBULANCE":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare;
    //     break;
    //   case "AIR_CARGO":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare;
    //     break;
    //   case "TRAIN_AMBULANCE":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare;
    //     break;
    //   case "EMBALMING":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isServiceLocationValid && _isPatientInfoValid && !!state.fareAgreed;
    //     break;
    //   case "CREMATION":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isServiceLocationValid && _isPatientInfoValid && !!state.fareAgreed;
    //     break;
    //   case "HOME_FREEZER_BOX":
    //     _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isServiceLocationValid && _isPatientInfoValid && !!state.fareAgreed;
    //     break;
    //   case "SDE":
    //     _isReadyToDispatch = _isFromAndToDateFill && _isServiceSectionValid && _isDispatchModeValid && _isServiceLocationValid && _isVehicleDetailsValid && !!state.fareAgreed;
    //     break;
    //   default:
    //     break;
    // }

    switch (service) {
      case "STANDARD":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && _isAddonSectionValid;
        break;

      case "ORGAN_TRANSPORTATION":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        // _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid;
        break;
      case "AIR_AMBULANCE":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        break;
      case "AIR_CARGO":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        break;
      case "TRAIN_AMBULANCE":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        break;
      case "EMBALMING":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        // _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid;
        break;
      case "CREMATION":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        break;
      case "HOME_FREEZER_BOX":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        break;
      case "DEAD_BODY":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && _isAddonSectionValid;
        break;
      case "SBE":
        _isReadyToDispatch = _isServiceSectionValid && _isDispatchModeValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && !!state.overwriteFare && _isAddonSectionValid;
        // _isReadyToDispatch = _isFromAndToDateFill && _isServiceSectionValid && _isDispatchModeValid && _isServiceLocationValid && _isVehicleDetailsValid && !!state.fareAgreed;
        break;
      default:
        break;
    }

    // override the state
    if (state.isOverridenPrice) {
      if (state.overwriteBillTo === "BTC") {
        _isReadyToDispatch = _isReadyToDispatch && !!state.overridenComments;
      } else {
        _isReadyToDispatch = _isReadyToDispatch && !!state.overridenComments && !!+state.overwriteFare;
      }
    }

    const isBthCase = state?.isOverridenPrice ? state?.overwriteBillTo === "BTC" : state?.billToClient;
    const isScheduled = state?.dispatchType === "SCHEDULED" && state?.scheduledDdtm;
    // const _is_is_more_then_x_km = (state?.distance && parseInt(state.distance) > X_DISTANCE) || false;
    const _is_is_more_then_x_km = state._is_more_then_x_km;
    const _dispatch_showAssignmentButton = HM_CAN_DISPATCH_SERVICE_TYPE.includes(state?.serviceType) && !isScheduled && isBthCase && !_is_is_more_then_x_km;
    const _dispatch_showCollectAdvanceButton = HM_CAN_DISPATCH_SERVICE_TYPE.includes(state?.serviceType) && !isBthCase;
    const _dispatch_showScheduleButton = HM_CAN_DISPATCH_SERVICE_TYPE.includes(state?.serviceType) && isScheduled && isBthCase && !_is_is_more_then_x_km;
    const _dispatch_collect_advance_other_service_type = !HM_CAN_DISPATCH_SERVICE_TYPE.includes(state?.serviceType);
    const _x_km_dispatch = HM_CAN_DISPATCH_SERVICE_TYPE.includes(state?.serviceType) && _is_is_more_then_x_km && isBthCase;

    const obj: any = {
      _isServiceSectionValid,
      _isDispatchModeValid,
      _isWaypointValid,
      _isPatientDetailsValid,
      _isVehicleDetailsValid,
      _isAddonValid,
      _isReadyToDispatch,
      _dispatch_showAssignmentButton,
      _dispatch_showCollectAdvanceButton,
      _dispatch_showScheduleButton,
      _isServiceLocationValid,
      _isPatientInfoValid,
      _isFromAndToDateFill,
      _dispatch_collect_advance_other_service_type,
      _x_km_dispatch,
    };
    // console.log("----> this is <----", obj);

    acc(obj);
  };

  useEffect(() => {
    const changeObserver = useRedStore.subscribe(state => observe({ ...state.localOrderChanges, selectedAddons: state.selectedAddons }));

    return () => changeObserver();
  }, []);

  return access;
}
