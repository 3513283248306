import { Box, Flex, Heading, Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import AsyncSelect from "react-select/async";
import { getICDData } from "../../api/service/app";
import { GENDER_OPTIONS, SYMPTOMS } from "../../config/constant";
import Select from "react-select";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { useCallback } from "react";
import { debounce } from "../../utils";
import InputBox from "@components/atom/InputBox";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import HightInput from "@components/atom/HightInput";

export default function PatientInfo() {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const { __updatePatientWeight, __updatePatientHeight, __updateAge, __updateGender, __updatePatientName } = useOrderAction();

  const promiseOptions = useCallback(
    debounce((inputValue, callback) => {
      getICDData(inputValue).then(options => callback(options));
    }, 300),
    []
  );

  return (
    <Box>
      <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4} pt={0.25}>
        <InputBox defaultValue={currentCase?.requestedFor?.name} label="Patient Name" onChange={(e: any) => __updatePatientName(e.target.value)} />
        <Flex gap={4} mt={2}>
          <Box mt={4}>
            <Flex align={"center"} justify={"flex-start"}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={1} color={"primary.600"} ml={-1.5}>
                <Icon icon={"fluent:book-number-24-filled"} fontSize={22} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Age in Years
              </Heading>
            </Flex>
            <InputGroup size="sm">
              <Input h={9} placeholder="Age" type="tel" value={currentCase?.requestedFor?.age || 0} borderLeftRadius={"base"} onChange={e => __updateAge(parseInt(e.target.value))} />
              <InputRightAddon h={9} children="Years" fontSize={"xs"} fontWeight={"semibold"} borderRightRadius={"base"} />
            </InputGroup>
          </Box>
          <Box mt={4}>
            <Flex align={"center"} justify={"flex-start"}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} h={8} w={8} mr={1} ml={-1.5} color={"primary.600"}>
                <Icon icon={"ph:gender-intersex-fill"} fontSize={22} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Gender
              </Heading>
            </Flex>

            <Select
              defaultInputValue=""
              options={GENDER_OPTIONS}
              placeholder={"Gender"}
              value={currentCase?.requestedFor?.gender || ""}
              onChange={(e: any) => __updateGender(e)}
              isClearable={false}
              styles={{
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  fontSize: 12,
                  fontWeight: 500,
                  minWidth: 150,
                  maxWidth: 170,
                  minHeight: "35px",
                  height: "35px",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  height: "35px",
                  padding: "0 6px",
                }),
                input: (provided: any) => ({
                  ...provided,
                  margin: "0px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                indicatorsContainer: (provided: any) => ({
                  ...provided,
                  height: "35px",
                }),
                option: (base: any) => ({
                  ...base,
                  zIndex: 9998,
                  fontSize: 14,
                  fontWeight: 500,
                  letterSpacing: "0.02em",
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "black",
                }),
              }}
            />
          </Box>
        </Flex>
        <Flex gap={4} mt={2}>
          <HightInput />
          <Box mt={4} ml={2}>
            <Flex align={"center"} justify={"flex-start"}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={1} color={"primary.600"} ml={-1.5}>
                <Icon icon={"icon-park-twotone:weight"} fontSize={22} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Weight
              </Heading>
            </Flex>
            <InputGroup size="sm">
              <Input h={9} placeholder="Weight" type="tel" value={currentCase?.requestedFor?.weight || 0} borderLeftRadius={"base"} onChange={e => __updatePatientWeight(parseInt(e.target.value))} />
              <InputRightAddon h={9} children="Kg" fontSize={"xs"} fontWeight={"semibold"} borderRightRadius={"base"} />
            </InputGroup>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
