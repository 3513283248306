import React from "react";
import { Center, Divider, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { CASE_TYPE } from "../../config/constant";

interface IPickupDropInfoSection {
  distance: number | string;
  caseType: keyof typeof CASE_TYPE | any;
  isLoading: boolean;
}

const PickupDropInfoSection: React.FC<IPickupDropInfoSection> = React.memo(({ distance, caseType, isLoading }) => {
  return (
    <>
      {/* <Divider /> */}
      <Stack gap={1} mt={2} bg={"purple.50"} borderWidth={1} borderRadius={"md"} p={2}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            <Flex align={"center"} gap={2}>
              <Text fontWeight={"semibold"} fontSize={"sm"} textTransform={"capitalize"}>
                Distance:
              </Text>
              <Text fontWeight={"normal"} fontSize={"sm"}>
                {distance} KM
              </Text>
            </Flex>
            {caseType && (
              <Flex align={"flex-start"} gap={2}>
                <Text fontWeight={"semibold"} fontSize={"sm"} textTransform={"capitalize"}>
                  {caseType?.toString().toLowerCase()}:
                </Text>
                <Text fontWeight={"normal"} fontSize={"sm"}>
                  {CASE_TYPE[caseType] || "-"}
                </Text>
              </Flex>
            )}
          </>
        )}
      </Stack>
    </>
  );
});

export default PickupDropInfoSection;
