import React, { useEffect } from "react";
import PickupDropSection from "./PickupDropSection";
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import PickupDropInfoSection from "./PickupDropInfoSection";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { usePricingHook } from "../../hooks/api/useCaseApi";
import { WaypointsEntity } from "../../config/types";
import { getProperty } from "dot-prop";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useOrder } from "@hooks/api/useSave";
import { chain, debounce, drop, every, some } from "lodash";
import toast from "react-hot-toast";
import DifferentSource from "@components/molecules/DifferentSource";
import MapLocation from "@components/molecules/MapLocation";
import MapLocationPicker from "@components/molecules/MapLocationPicker";
import { createCaseSwipeWaypoints, createCaseWaypointSelection } from "@config/event";
import { flattenObject } from "@utils";

export default function PickupDropContainer() {
  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const pricing: any = useRedStore(state => state.price);
  const { syncWaypoint, multiSetupUpdate } = useOrder();
  const doMultiExecute = debounce(multiSetupUpdate, 250);

  const { __updateWaypoints } = useOrderAction();

  const { execute, isLoading } = usePricingHook();
  const dbExecute = debounce(execute, 300);

  const onPickupSelect = (address: any) => {
    address && createCaseWaypointSelection(flattenObject(address));
    __updateWaypoints(address, null);
  };

  const onDropSelect = (address: any) => {
    address && createCaseWaypointSelection(flattenObject(address));
    __updateWaypoints(null, address);
  };

  const waypoint1: any = (localOrderChanges?.waypoints && localOrderChanges?.waypoints[0]) || (order?.waypoints && order?.waypoints[0]);
  const waypoint2: any = (localOrderChanges?.waypoints && localOrderChanges?.waypoints[1]) || (order?.waypoints && order?.waypoints[1]);

  const hasPickupWaypoint = waypoint1?.address;
  const hasDropWaypoint = waypoint2?.address;

  const isSameWaypoint1 =
    getProperty(localOrderChanges, "waypoints[0].address.location") === getProperty(order, "waypoints[0].address.location") &&
    getProperty(localOrderChanges, "waypoints[0].type") === getProperty(order, "waypoints[0].type");

  const isSameWaypoint2 =
    getProperty(localOrderChanges, "waypoints[1].address.location") === getProperty(order, "waypoints[1].address.location") &&
    getProperty(localOrderChanges, "waypoints[1].type") === getProperty(order, "waypoints[1].type");

  const hasCityChange = !!localOrderChanges?.city && localOrderChanges?.city;
  const hasSourceChange = getProperty(localOrderChanges, "originSource.source") !== getProperty(order, "originSource.source");
  // !!localOrderChanges?.selectedCity?.value && order?.originSource?.source;
  const isSameCovidCaseFlag = getProperty(order, "isCovidCase") !== getProperty(localOrderChanges, "isCovidCase");
  const isLamaDataCaseFlag = getProperty(order, "isLamaDamaSelected") !== getProperty(localOrderChanges, "isLamaDamaSelected");
  const hasVehicleChange = getProperty(order, "entityRequired[0].subtype") !== getProperty(localOrderChanges, "entityRequired[0].subtype");

  useEffect(() => {
    if (hasPickupWaypoint && hasDropWaypoint) {
      doMultiExecute();
    }
  }, [hasPickupWaypoint, hasDropWaypoint, isSameWaypoint1, isSameWaypoint2, isSameCovidCaseFlag, isLamaDataCaseFlag, hasCityChange, hasSourceChange, hasVehicleChange]);

  const handleSwapWaypoints = () => {
    const locationA = { ...waypoint1, type: "dropoff", sequence: 1 };
    const locationB = { ...waypoint2, type: "pickup", sequence: 0 };

    if (hasPickupWaypoint && hasDropWaypoint) {
      __updateWaypoints(locationB, locationA);
      createCaseSwipeWaypoints(flattenObject({ orderId: order?.orderId, dropoff: locationA, pickup: locationB }));
    }
  };

  return (
    <Stack p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
      <PickupDropSection
        defaultValue={waypoint1}
        withPlaceApi={true}
        icon="bxs:map-pin"
        title="Pickup Location"
        type="pickup"
        onSelect={onPickupSelect}
        sequence={0}
        value={waypoint1?.sourceId}
        isDefaultPartnerSearchSelect={!waypoint1?.address || !!waypoint1?.sourceId || !waypoint1}
        serviceType={localOrderChanges.serviceType}
      />
      {!getProperty(waypoint1, "address.pincode") && getProperty(waypoint1, "address.formatted_address") && (
        <Flex align={"start"}>
          <Box mt={-1.5}>
            <Icon icon={"ri:alert-fill"} fontSize={20} color="red" />
          </Box>
          <Text color={"red"} fontSize={"sm"} mt={-2} ml={2}>
            Please choose different address, <strong>PIN</strong> number is missing.
          </Text>
        </Flex>
      )}
      <Center color={hasPickupWaypoint && hasDropWaypoint ? "primary.600" : "gray.400"} mb={-4}>
        <Box cursor={"pointer"} onClick={handleSwapWaypoints}>
          <Icon icon={"ri:swap-fill"} fontSize={24} />
        </Box>
      </Center>
      <PickupDropSection
        defaultValue={waypoint2}
        icon="ri:map-pin-range-line"
        title="Drop Location"
        type="dropoff"
        onSelect={onDropSelect}
        sequence={1}
        value={waypoint2?.sourceId}
        isDefaultPartnerSearchSelect={!waypoint2?.address || !!waypoint2?.sourceId || !waypoint2}
        serviceType={localOrderChanges.serviceType}
      />
      {!getProperty(waypoint2, "address.pincode") && getProperty(waypoint2, "address.formatted_address") && (
        <Flex align={"start"}>
          <Box mt={-1.5}>
            <Icon icon={"ri:alert-fill"} fontSize={20} color="red" />
          </Box>
          <Text color={"red"} fontSize={"sm"} mt={-2} ml={2}>
            Please choose different address, <strong>PIN</strong> number is missing.
          </Text>
        </Flex>
      )}

      <PickupDropPin />
      {waypoint1 && waypoint2 && !isNaN(pricing?.distance) && (
        <PickupDropInfoSection
          caseType={localOrderChanges?.orderClassification || order?.orderClassification}
          distance={parseFloat((pricing?.distance / 1000)?.toString())?.toFixed(2)}
          isLoading={isLoading}
        />
      )}
      <DifferentSource />
    </Stack>
  );
}

const PickupDropPin = () => {
  // return <MapLocation/>
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [waypoints, serviceType, locationTypeSelection] = useRedStore(state => [state.localOrderChanges.waypoints, state.order.serviceType, state.locationTypeSelection]);

  const isEnabled = some(waypoints, "geo");
  const isBothPartner = every(waypoints, "sourceId");

  useEffect(() => {
    // console.log("Location change: ", locationTypeSelection, waypoints);

    const locType: any = locationTypeSelection;

    const selectedWaypoint: any = locType?.type === "pickup" ? waypoints[0] : waypoints[1];

    if (locType && locType?.type && selectedWaypoint && !selectedWaypoint?.sourceId) {
      const wayGeo = JSON.stringify(selectedWaypoint?.geo);
      const newGeo = JSON.stringify(locType?.address?.value?.geometry?.location);
      if (wayGeo !== newGeo) {
        onOpen();
      } else {
        onClose();
      }
    }

    // if (locType && locType?.type) {
    //   if (locType?.type === "pickup" && !waypoints[0]?.sourceId) {
    //     const way1Geo = JSON.stringify(waypoints[0]?.geo);
    //     const newtGeo = JSON.stringify(locType?.address.value.geometry.location);
    //     if (way1Geo !== newtGeo) {
    //       onOpen();
    //     } else {
    //       onClose();
    //     }
    //   } else if (!waypoints[1]?.sourceId) {
    //     const way1Geo = JSON.stringify(waypoints[1]?.geo);
    //     const newtGeo = JSON.stringify(locType?.address.value.geometry.location);
    //     if (way1Geo !== newtGeo) {
    //       onOpen();
    //     } else {
    //       onClose();
    //     }
    //   }
    //   console.log("ece");
    // } else {
    //   onClose();
    // }
    // onOpen();
  }, [locationTypeSelection]);

  return (
    <Box position="relative" my={0}>
      <Divider />
      {/* <Divider />
      <AbsoluteCenter bg="white" px="4">
        <Button
          rounded={"full"}
          size={"md"}
          h={8}
          variant="outline"
          fontWeight={"semibold"}
          textTransform={"capitalize"}
          px={4}
          py={1.5}
          onClick={onOpen}
          borderWidth={1}
          borderColor={"primary.300"}
          ml={1}
          fontSize={"xs"}
          gap={2}
          isDisabled={!isEnabled || isBothPartner}
          colorScheme="primary"
        >
          <Icon icon={"ph:map-pin-duotone"} fontSize={20} /> Select On Map
        </Button>
      </AbsoluteCenter> */}

      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} isFullHeight>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            {/* <Input placeholder='Type here...' /> */}
            {Array.isArray(waypoints) && waypoints?.length > 1 ? (
              <MapLocationPicker onClose={onClose} waypoints={waypoints} serviceType={serviceType} />
            ) : (
              <Text fontWeight={"semibold"} fontSize={"sm"}>
                Please select Pickup and Drop location.
              </Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
