import { Box, Divider, Flex, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Switch, Text, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { ChangeEvent, useEffect } from "react";
import SourceDropdown from "@components/molecules/SourceDropdown";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useSourceData } from "@hooks/api/useAppApi";
import { showSuccessToast } from "@utils";
import { overrideToLamaDamaToggle } from "@config/event";

export default function LamaDamaSwitch() {
  const setCaseProps = useCreateCaseStore(state => state.setCaseProps);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateLamadama } = useOrderAction();

  const { ...rest } = useSourceData();
  const data = useRedStore((state: any) => state.source);
  const order = useRedStore((state: any) => state.order);
  const { __updateSource } = useOrderAction();

  // const onSelect = (source: any) => {
  //   __updateSource(source.value, source.cityCode);
  // };

  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    overrideToLamaDamaToggle({ orderId: order?.orderId, isLamaDamaSelected: val });
    if (!val) {
      setCaseProps("originSource", "");
      onOpen();
    } else {
      const selected = data?.filter((itm: any) => itm?.name?.includes("Red Health"))?.filter((cty: any) => cty?.cityCode === (localOrderChanges?.city || order?.city));
      if (selected?.length > 0) {
        __updateSource(selected[0]?.branchId, selected[0]?.cityCode);
        showSuccessToast(`Auto selected ${selected[0]?.name} as source.`, 5000);
      } else {
        showSuccessToast("No source found for this city.");
      }
    }
    __updateLamadama(!!val);
  };

  useEffect(() => {
    if (localOrderChanges?.originSource) {
      onClose();
    }
  }, [localOrderChanges?.originSource]);

  return (
    <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
      <Flex justify={"space-between"} align={"center"}>
        <Flex align={"flex-start"} mt={1}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} maxH={8} minW={8} w={8} mr={2} bg={"purple.100"} color={"purple.800"}>
            <Icon icon={"fluent:patient-24-filled"} fontSize={20} />
          </Flex>
          <Box>
            <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
              {"Override to Lama/Dama Case"}
            </Heading>
            <Text fontSize={"xs"}>Toggle the switch to classify this as a Lama/Dama case.</Text>
          </Box>
        </Flex>
        <Switch isChecked={localOrderChanges?.isLamaDamaSelected} onChange={handleSwitch} colorScheme="purple" size="md" ml={5} />
      </Flex>
      <Modal onClose={() => {}} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx={2} bg={"white"}>
          <ModalHeader mb={-2} fontSize={"md"}>
            Please Select Source
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Text fontWeight={"normal"} fontSize={"xs"}>
              Case removed from LAMA/DAMA, Please select a source
            </Text>
            <Box mx={-2}>
              <SourceDropdown />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
