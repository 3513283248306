"use client";

import React, { useEffect } from "react";
import { Stack, Text, Button, useColorModeValue, Alert, useDisclosure, Popover, PopoverContent, Modal, ModalOverlay, ModalContent, ModalBody, Center } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useRedStore } from "@store/store";
// import { FcLock } from 'react-icons/fc'

export default function DifferentSource() {
  const cardSlateBg = useColorModeValue("slate.800", "white");
  const { onOpen, onClose, isOpen } = useDisclosure();
  const localOrderChanges = useRedStore(state => state.localOrderChanges);

  const dboun = debounce(onOpen, 100);
  const dbounCl = debounce(onClose, 100);

  useEffect(() => {
    const waypoints: any = localOrderChanges?.waypoints;
    if (waypoints[0]?.geo && waypoints[1]?.geo && ![waypoints[0]?.sourceId, waypoints[1]?.sourceId]?.includes(localOrderChanges.originSource.source)) {
      dboun();
    } else {
      dbounCl();
    }
  }, [localOrderChanges?.waypoints, localOrderChanges.originSource.source]);

  return isOpen ? (
    <Text fontSize={"sm"} color={"red.500"}>
      📢 The source you have selected does not match with pickup or drop location
    </Text>
  ) : (
    <></>
  );
}

{
  /* <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent m={4} borderRadius={"2xl"} bg={"transparent"}>
        <Center h={"90vh"}>
          <Stack shadow={"2xl"} p="4" boxShadow="lg" bg={cardSlateBg} borderRadius={"xl"} w={"full"}>
            <Stack direction="row" alignItems="center">
              <Text fontWeight="semibold" color={"whiteAlpha.800"}>
                Source Not Match
              </Text>
              {/* <FcLock /> */
}
//         </Stack>

//         <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between">
//           <Text color={"whiteAlpha.700"} fontSize={"md"} textAlign={"left"} maxW={"4xl"}>
//             The source you have selected does not match with pickup or drop location
//           </Text>
//           <Stack mt={5} direction={{ base: "column", md: "row" }}>
//             <Button colorScheme="green" onClick={onClose}>
//               Check Now
//             </Button>
//           </Stack>
//         </Stack>
//       </Stack>
//     </Center>
//   </ModalContent>
// </Modal> */}
