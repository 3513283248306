import { useNavigate, useParams } from "react-router-dom";
import { useGetCase, useOrderPricingHook, usePricingHook, useSyncPickup } from "@hooks/api/useCaseApi";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { Box, Stack } from "@chakra-ui/react";
import {
  SourceDropdown,
  CityDropDown,
  AttributionDropDown,
  ServiceTypeDropDown,
  SaveAsEnquirySection,
  DispatchType,
  PickupDropContainer,
  MedicalReasonDropDown,
  AmbulanceTypeSection,
  AddonSection,
  PriceSection,
  DispatchActionGroup,
  CovidSwitch,
  LamaDamaSwitch,
} from "@components/molecules";

import { usePageTitle, useTitle } from "@hooks/action/useUtils";
import useFeature from "@hooks/action/useFeature";
import useCaseObserver from "@hooks/action/useCaseObserver";
import usePriceAction from "@store/action-hook/usePriceAction";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useUnmount } from "usehooks-ts";
import useTemplate from "@hooks/action/useTemplate";
import EnterFareAgreed from "@components/molecules/EnterFareAgreed";
import { useRedStore } from "@store/store";
import ServiceLocation from "@components/molecules/ServiceLocation";
import PatientInfo from "@components/molecules/PatientInfo";
import DateRangePickupDropSection from "@components/molecules/DateRangePickupDropSection";
import { showSuccessToast } from "@utils";
import { debounce } from "lodash";
import DepartmentTypeDropDown from "@components/molecules/DepartmentTypeDropDown";
// import { FpsView } from "react-fps";

export default function CreateCase() {
  const navigate = useNavigate();
  useTitle("Create Case");
  const { id: orderId } = useParams();
  const { execute, isLoading } = useOrderPricingHook();
  const dbExecute = debounce(execute, 300);

  const { ...rest }: any = useGetCase({
    orderId,
    onSuccess: (data: any) => {
      dbExecute(data?.data?.data);
      if (data?.data?.data?.bookingType === "BOOKING") {
        showSuccessToast("This case is already convert to BOOKING.");
        navigate(`/`);
      }
    },
  });

  useSyncPickup({
    orderId,
    onSuccess: (data: any) => {
      // showSuccessToast("Refetched.");
    },
  });

  const localCase = useRedStore(state => state.localOrderChanges);
  const { hasAccess } = useFeature();
  const { __resetPrice } = usePriceAction();
  const { __resetOrderStore } = useOrderAction();
  const { _isFromAndToDateFill, _isPatientInfoValid, _isDispatchModeValid, _isPatientDetailsValid, _isServiceSectionValid, _isVehicleDetailsValid, _isWaypointValid, _isServiceLocationValid } =
    useCaseObserver();

  useUnmount(() => {
    __resetPrice();
    __resetOrderStore();
  });

  const { alowIslands } = useTemplate();

  // const templateFn: any = {
  //   ORGAN_TRANSPORTATION: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,

  //     AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
  //   },
  //   AIR_CARGO: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
  //   },
  //   TRAIN_AMBULANCE: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
  //   },
  //   AIR_AMBULANCE: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
  //   },
  //   EMBALMING: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     SERVICE_LOCATION_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_INFO_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isPatientInfoValid,
  //   },
  //   SBE: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     SERVICE_LOCATION_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PICKUP_DROP_DATE_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid,
  //     AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isFromAndToDateFill,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isVehicleDetailsValid && _isFromAndToDateFill,
  //   },
  //   HOME_FREEZER_BOX: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     SERVICE_LOCATION_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_INFO_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isPatientInfoValid,
  //   },
  //   CREMATION: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     SERVICE_LOCATION_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     PATIENT_INFO_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isPatientInfoValid,
  //   },
  //   STANDARD: {
  //     DISPATCH_ISLAND: _isServiceSectionValid,
  //     PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
  //     LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
  //     AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
  //     ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
  //     PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
  //     CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
  //   },
  // };
  const templateFn: any = {
    ORGAN_TRANSPORTATION: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
      // DISPATCH_ISLAND: _isServiceSectionValid,
      // PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      // LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      // ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      // PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      // CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
    AIR_CARGO: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
    },
    TRAIN_AMBULANCE: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
    },
    AIR_AMBULANCE: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
    },
    EMBALMING: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
      // DISPATCH_ISLAND: _isServiceSectionValid,
      // PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      // LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      // ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      // PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      // CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
    SBE: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
      // DISPATCH_ISLAND: _isServiceSectionValid,
      // SERVICE_LOCATION_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      // PICKUP_DROP_DATE_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid,
      // AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isFromAndToDateFill,
      // CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isServiceLocationValid && _isVehicleDetailsValid && _isFromAndToDateFill,
    },
    HOME_FREEZER_BOX: {
      // DISPATCH_ISLAND: _isServiceSectionValid,
      // PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      // LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      // ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      // PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      // CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
    CREMATION: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
      // DISPATCH_ISLAND: _isServiceSectionValid,
      // PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      // LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      // AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      // ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      // PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      // CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
    STANDARD: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
    DEAD_BODY: {
      DISPATCH_ISLAND: _isServiceSectionValid,
      PICKUP_DROP_ISLAND: _isDispatchModeValid && _isServiceSectionValid,
      LAMA_DAMA_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      PATIENT_DETAILS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      COVID_SWITCH_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid,
      AMBULANCE_SELECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid,
      ADDONS_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid && localCase.dispatchType === "SCHEDULED",
      PRICE_SECTION_ISLAND: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isPatientDetailsValid && _isVehicleDetailsValid,
      CUSTOM_FARE_AGREED: _isDispatchModeValid && _isServiceSectionValid && _isWaypointValid && _isVehicleDetailsValid,
    },
  };

  const getAccess = (key: any) => {
    const serviceType = localCase.serviceType;
    if (!serviceType) return false;

    const liveChanges = templateFn[serviceType][key];
    const featureLevel = !!alowIslands[key];
    return liveChanges && featureLevel;
  };

  return (
    <NetworkWrapper status={rest}>
      <Stack gap={0} m={-3} mb={5}>
        {/* <FpsView width={240} height={180} left={60} top={80} /> */}
        <SaveAsEnquirySection />
        <Box className="fade_in_animation" zIndex={"sticky"}>
          <Stack p={1} mx={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={1}>
            <SourceDropdown />
            <AttributionDropDown />
            <CityDropDown />
            <ServiceTypeDropDown />
            <DepartmentTypeDropDown />
          </Stack>
        </Box>

        {getAccess("DISPATCH_ISLAND") && (
          <Box className="fade_in_animation" zIndex={"base"} pos={"relative"}>
            <DispatchType />
          </Box>
        )}
        {getAccess("PICKUP_DROP_ISLAND") && (
          <Box className="fade_in_animation" zIndex={"dropdown"} pos={"relative"}>
            <PickupDropContainer />
          </Box>
        )}

        {getAccess("SERVICE_LOCATION_ISLAND") && (
          <Box className="fade_in_animation" zIndex={"base"} pos={"relative"}>
            <ServiceLocation />
          </Box>
        )}

        {getAccess("PICKUP_DROP_DATE_ISLAND") && (
          <Box className="fade_in_animation" zIndex={"base"} pos={"relative"}>
            <DateRangePickupDropSection />
          </Box>
        )}

        {hasAccess("Lama/Dama Feature") && getAccess("LAMA_DAMA_ISLAND") && (
          <Box className="fade_in_animation" zIndex={"base"} pos={"relative"}>
            <LamaDamaSwitch />
          </Box>
        )}

        {getAccess("PATIENT_INFO_ISLAND") && (
          <Box className="fade_in_animation">
            <PatientInfo />
          </Box>
        )}

        {getAccess("PATIENT_DETAILS_ISLAND") && (
          <Box className="fade_in_animation">
            <MedicalReasonDropDown />
          </Box>
        )}

        {hasAccess("Covid Feature") && getAccess("COVID_SWITCH_ISLAND") && (
          <Box className="fade_in_animation">
            <CovidSwitch />
          </Box>
        )}

        {getAccess("AMBULANCE_SELECTION_ISLAND") && (
          <Box className="fade_in_animation">
            <AmbulanceTypeSection />
          </Box>
        )}
        {getAccess("ADDONS_ISLAND") && (
          <Box className="fade_in_animation">
            <AddonSection />
          </Box>
        )}
        {getAccess("PRICE_SECTION_ISLAND") && (
          <Box className="fade_in_animation">
            <PriceSection />
          </Box>
        )}

        {getAccess("CUSTOM_FARE_AGREED") && (
          <Box className="fade_in_animation">
            <EnterFareAgreed />
          </Box>
        )}

        <Box className="fade_in_animation">
          <DispatchActionGroup />
        </Box>
      </Stack>
    </NetworkWrapper>
  );
}
