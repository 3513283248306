import { Box, Flex, Heading, Skeleton, Spinner, Tooltip } from "@chakra-ui/react";

import { Icon } from "@iconify/react";
import { IKeyValue, ILabelValue } from "../../config/types";
import { InfoIcon } from "@chakra-ui/icons";

import Select from "react-select";
import { useEffect } from "react";

interface IDropDownSection {
  icon: string;
  title: string;
  options: ILabelValue[];
  onSelect: (value?: IKeyValue) => void;
  isRequired?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  maxWidth?: number;
  minWidth?: number;
  tollTip?: string;
  defaultValue?: string;
  value?: any;
  defaultInputValue?: any;
  isDisabled?: boolean;
  onInputChange?: any;
}

const DropDownSectionV2 = ({
  icon,
  title,
  options,
  onSelect,
  isRequired,
  placeholder,
  isLoading,
  isClearable,
  maxWidth,
  minWidth,
  tollTip,
  defaultValue,
  value,
  defaultInputValue,
  isDisabled = false,
  onInputChange,
}: IDropDownSection) => {
  useEffect(() => {}, [options, isLoading]);
  return (
    <Box m={2}>
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <Flex align={"center"}>
            <Flex justify={"center"} align={"center"} bg={"primary.100"} borderRadius={"full"} mr={2} color={"primary.600"} pos={"relative"} h={8} w={8}>
              <Icon icon={icon} fontSize={16} />
              {isRequired && (
                <Box color={"red"} pos={"absolute"} fontSize={20} right={-1.5} top={-2}>
                  *
                </Box>
              )}
            </Flex>
            <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
              {title}
            </Heading>
            {tollTip && (
              <Tooltip hasArrow label={tollTip} bg="gray.800" color="white">
                <InfoIcon fontSize={"xs"} ml={2} color="primary.600" />
              </Tooltip>
            )}
          </Flex>
        </Box>
        <Box>
          {isLoading ? (
            <Skeleton w={"full"} h={"35px"} width={"50vw"}>
              <div>contents wrapped</div>
              <div>won't be visible</div>
            </Skeleton>
          ) : (
            <Select
              isDisabled={isDisabled}
              options={options}
              filterOption={null}
              onInputChange={onInputChange ? onInputChange : () => console.log("Search..")}
              defaultInputValue={defaultInputValue}
              value={options.find(itm => itm.value === value)}
              placeholder={placeholder}
              isLoading={isLoading}
              onChange={(e: any) => onSelect(e)}
              isClearable={isClearable}
              defaultValue={options.find(itm => itm.value === defaultValue)}
              styles={{
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  fontSize: 12,
                  fontWeight: 700,
                  minWidth: minWidth || 200,
                  maxWidth: maxWidth || 200,
                  minHeight: "35px",
                  height: "35px",
                }),
                valueContainer: provided => ({
                  ...provided,
                  height: "35px",
                  padding: "0 6px",
                }),
                input: provided => ({
                  ...provided,
                  margin: "0px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                indicatorsContainer: provided => ({
                  ...provided,
                  height: "30px",
                }),
                option: (base: any) => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: 14,
                  fontWeight: 500,
                  letterSpacing: "0.02em",
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "black",
                }),
              }}
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};
export default DropDownSectionV2;
