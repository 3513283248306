const config: any = {
  ORGAN_TRANSPORTATION: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
    // DISPATCH_ISLAND: 1,
    // PICKUP_DROP_ISLAND: 1,
    // SERVICE_LOCATION_ISLAND: 0,
    // PICKUP_DROP_DATE_ISLAND: 0,
    // LAMA_DAMA_ISLAND: 0,
    // PATIENT_DETAILS_ISLAND: 0,
    // PATIENT_INFO_ISLAND: 0,
    // COVID_SWITCH_ISLAND: 0,
    // AMBULANCE_SELECTION_ISLAND: 1,
    // ADDONS_ISLAND: 0,
    // PRICE_SECTION_ISLAND: 0,
    // CUSTOM_FARE_AGREED: 1,
  },
  AIR_AMBULANCE: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 0,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
  },
  TRAIN_AMBULANCE: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 0,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
  },
  AIR_CARGO: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 0,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
  },
  STANDARD: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 1,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 1,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 1,
    PRICE_SECTION_ISLAND: 1,
    CUSTOM_FARE_AGREED: 0,
  },
  EMBALMING: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,

    // DISPATCH_ISLAND: 1,
    // PICKUP_DROP_ISLAND: 0,
    // SERVICE_LOCATION_ISLAND: 1,
    // PICKUP_DROP_DATE_ISLAND: 0,
    // LAMA_DAMA_ISLAND: 0,
    // PATIENT_DETAILS_ISLAND: 0,
    // PATIENT_INFO_ISLAND: 1,
    // COVID_SWITCH_ISLAND: 0,
    // AMBULANCE_SELECTION_ISLAND: 0,
    // ADDONS_ISLAND: 0,
    // PRICE_SECTION_ISLAND: 0,
    // CUSTOM_FARE_AGREED: 1,
  },
  CREMATION: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
    // DISPATCH_ISLAND: 1,
    // PICKUP_DROP_ISLAND: 0,
    // SERVICE_LOCATION_ISLAND: 1,
    // PICKUP_DROP_DATE_ISLAND: 0,
    // LAMA_DAMA_ISLAND: 0,
    // PATIENT_DETAILS_ISLAND: 0,
    // PATIENT_INFO_ISLAND: 1,
    // COVID_SWITCH_ISLAND: 0,
    // AMBULANCE_SELECTION_ISLAND: 0,
    // ADDONS_ISLAND: 0,
    // PRICE_SECTION_ISLAND: 0,
    // CUSTOM_FARE_AGREED: 1,
  },
  HOME_FREEZER_BOX: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
    // DISPATCH_ISLAND: 1,
    // PICKUP_DROP_ISLAND: 0,
    // SERVICE_LOCATION_ISLAND: 1,
    // PICKUP_DROP_DATE_ISLAND: 0,
    // LAMA_DAMA_ISLAND: 0,
    // PATIENT_DETAILS_ISLAND: 0,
    // PATIENT_INFO_ISLAND: 1,
    // COVID_SWITCH_ISLAND: 0,
    // AMBULANCE_SELECTION_ISLAND: 0,
    // ADDONS_ISLAND: 0,
    // PRICE_SECTION_ISLAND: 0,
    // CUSTOM_FARE_AGREED: 1,
  },
  DEAD_BODY: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 1,
    PRICE_SECTION_ISLAND: 1,
    CUSTOM_FARE_AGREED: 0,
  },
  SBE: {
    DISPATCH_ISLAND: 1,
    PICKUP_DROP_ISLAND: 1,
    SERVICE_LOCATION_ISLAND: 0,
    PICKUP_DROP_DATE_ISLAND: 0,
    LAMA_DAMA_ISLAND: 0,
    PATIENT_DETAILS_ISLAND: 1,
    PATIENT_INFO_ISLAND: 0,
    COVID_SWITCH_ISLAND: 0,
    AMBULANCE_SELECTION_ISLAND: 1,
    ADDONS_ISLAND: 0,
    PRICE_SECTION_ISLAND: 0,
    CUSTOM_FARE_AGREED: 1,
    // DISPATCH_ISLAND: 1,
    // PICKUP_DROP_ISLAND: 0,
    // SERVICE_LOCATION_ISLAND: 1,
    // PICKUP_DROP_DATE_ISLAND: 1,
    // LAMA_DAMA_ISLAND: 0,
    // PATIENT_DETAILS_ISLAND: 0,
    // PATIENT_INFO_ISLAND: 0,
    // COVID_SWITCH_ISLAND: 0,
    // AMBULANCE_SELECTION_ISLAND: 1,
    // ADDONS_ISLAND: 0,
    // PRICE_SECTION_ISLAND: 0,
    // CUSTOM_FARE_AGREED: 1,
  },
};
export default config;
