import { useQuery } from "react-query";
import { getCites, getMdmAmbulance, getMdmUser } from "../../api/service/mdm";
import { useState } from "react";
import { objToQuery } from "@utils";

const useCity = () => {
  return useQuery([`cites`], getCites, {
    refetchOnWindowFocus: false,
  });
};

export { useCity };

const DISABLED_KEY = ["manual-dispatch-page-x"];

export default function useMdmVehicleData({ onSuccess, ambulanceNumber, componentKey }: any) {
  const KEY = `mdm-order-id-${ambulanceNumber}`;

  const isThisFeatureActive = componentKey ? !DISABLED_KEY?.includes(componentKey) : true;

  const request = useQuery(KEY, getMdmAmbulance(ambulanceNumber || ""), {
    enabled: !!ambulanceNumber && isThisFeatureActive,
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data?.data);
    },
  });

  return request;
}

// ?eligibleRole=Collection%20Agent&name=as&limit=20

export function useMdmUserSearch({ onSuccess, collectionAgentName }: any) {
  const KEY = `mdm-user-id-${collectionAgentName}`;

  const query = {
    eligibleRole: "Collection Agent",
    name: collectionAgentName,
    limit: 20,
  };

  const request = useQuery(KEY, () => getMdmUser(objToQuery(query)), {
    enabled: !!collectionAgentName,
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data?.data);
    },
  });

  return request;
}
