import DropDownSection from "../atom/DropDownSection";
import { useSourceData } from "../../hooks/api/useAppApi";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { memo, useEffect } from "react";
import DropDownSectionV2 from "@components/atom/DropDownSectionV2";
import { createCaseSourceChangeTo } from "@config/event";

const prepData = (data: any): any => {
  const newData =
    data &&
    Object.values(data)?.map((itm: any) => ({
      // label: `${itm.city} - ${itm.name}`,
      label: `${itm.name}`,
      value: itm?.branchId,
      cityCode: itm.cityCode,
    }));

  return newData || [];
};

const SourceDropdown = memo(() => {
  const { setSearchKey, filteredOptions, ...rest } = useSourceData({ prepData });

  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateSource } = useOrderAction();

  const onSelect = (source: any) => {
    createCaseSourceChangeTo({ sourceId: source?.value, orderId: order?.orderId });
    __updateSource(source.value, source.cityCode);
  };

  useEffect(() => {}, [filteredOptions]);

  return (
    <DropDownSectionV2
      onInputChange={setSearchKey}
      defaultValue={order.originSource.source}
      value={localOrderChanges?.originSource?.source}
      icon="ph:bank-bold"
      title="Source"
      options={filteredOptions}
      onSelect={onSelect}
      isLoading={rest?.isLoading}
    />
  );
});

export default SourceDropdown;
