import ActionButton from "@components/atom/ActionButton";
import useCaseObserver from "@hooks/action/useCaseObserver";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useOrder } from "@hooks/api/useSave";
import { convertDistance, showSuccessToast } from "@utils";
import { X_DISTANCE } from "@config/constant";
import { useRedStore } from "@store/store";
import { createCaseFormSubmitButtonClicked } from "@config/event";

export default function DispatchActionGroup() {
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const { collectAdvanceForOtherType, collectAdvance, dispatchScheduled, moveToAssignment, ...rest } = useOrder();
  // const _is_more_then_x_km = useRedStore(state => state.localOrderChanges._is_more_then_x_km)

  const { _isReadyToDispatch, _dispatch_showScheduleButton, _dispatch_showAssignmentButton, _dispatch_showCollectAdvanceButton, _dispatch_collect_advance_other_service_type, _x_km_dispatch } =
    useCaseObserver();

  const [constToOwner, fareAgreed, isOverridenPrice, serviceType] = useRedStore(state => [
    state.constToOwner,
    state.localOrderChanges.fareAgreed,
    state.order?.isOverridenPrice,
    state.order.serviceType,
  ]);

  const onManualDispatch = () => {
    orderId &&
      createCaseFormSubmitButtonClicked({
        orderId: orderId,
        type: "Assign Ambulance",
        isOverridenPrice: isOverridenPrice,
        fareAgreed: fareAgreed,
        constToOwner: constToOwner,
      });
    moveToAssignment({
      onSuccess: () => {
        navigate(`/case/${orderId}/ready-to-dispatch`);
      },
    });
  };

  const onCollectAdvance = () => {
    orderId &&
      createCaseFormSubmitButtonClicked({
        orderId: orderId,
        type: "Collect Advance",
        isOverridenPrice: isOverridenPrice,
        fareAgreed: fareAgreed,
        constToOwner: constToOwner,
        serviceType: serviceType,
      });
    collectAdvance({
      onSuccess: () => {
        const margin = (((fareAgreed - constToOwner) / fareAgreed) * 100)?.toFixed(2);
        let url = `/case/${orderId}/payment`;
        if (!isOverridenPrice) {
          // [feature-saathi-pricing]
          url = url.concat(`?partnerCost=${constToOwner}&redMargin=${parseInt(margin)}`);
        }

        navigate(url);
      },
    });
  };

  const onCollectAdvanceForOtherServiceType = () => {
    orderId &&
      createCaseFormSubmitButtonClicked({
        orderId: orderId,
        type: "Collect Advance",
        isOverridenPrice: isOverridenPrice,
        fareAgreed: fareAgreed,
        constToOwner: constToOwner,
        serviceType: serviceType,
      });
    collectAdvanceForOtherType({
      onSuccess: () => {
        navigate(`/case/${orderId}/payment`);
        window.location.reload();
      },
    });
  };

  const onScheduled = () => {
    // 1. If the Case is BTH -> direct booking
    // 2. BTP -> show Payment -> booking
    orderId &&
      createCaseFormSubmitButtonClicked({
        orderId: orderId,
        type: "Scheduled",
        isOverridenPrice: isOverridenPrice,
        fareAgreed: fareAgreed,
        constToOwner: constToOwner,
        serviceType: serviceType,
      });

    dispatchScheduled({
      fromCasePage: true,
      onSuccess: () => {
        showSuccessToast("Your order Scheduled Successfully.");
        navigate(`/case-history/${orderId}/case-overview`);
      },
    });
  };

  const xDistanceClick = () => {
    orderId &&
      createCaseFormSubmitButtonClicked({
        orderId: orderId,
        type: "Request Central Dispatch",
        isOverridenPrice: isOverridenPrice,
        fareAgreed: fareAgreed,
        constToOwner: constToOwner,
        serviceType: serviceType,
        distance: X_DISTANCE || 0,
      });
    moveToAssignment({
      onSuccess: () => {
        showSuccessToast("Your order Successfully Send to Central Dispatch Team.");
        navigate(`/case-history/${orderId}/case-overview`);
      },
    });
  };

  const _isLoading = rest?.isLoading;

  return (
    <Box>
      <Flex justify={"center"} align={"center"} gap={4}>
        {_dispatch_showAssignmentButton && (
          <ActionButton isDisabled={!_isReadyToDispatch} isLoading={_isLoading} variant={"solid"} colorScheme="primary" borderWidth={1} label="Assign Ambulance" onClick={onManualDispatch} />
        )}
        {_dispatch_showScheduleButton && <ActionButton isDisabled={!_isReadyToDispatch} leftIcon={<Icon icon="uil:calender" />} label="Scheduled" onClick={onScheduled} />}
        {_dispatch_showCollectAdvanceButton && (
          <ActionButton isDisabled={!_isReadyToDispatch} leftIcon={<Icon icon="fluent:payment-32-regular" fontSize={20} />} label="Collect Advance" onClick={onCollectAdvance} isLoading={_isLoading} />
        )}
        {_dispatch_collect_advance_other_service_type && (
          <ActionButton
            isDisabled={!_isReadyToDispatch}
            leftIcon={<Icon icon="fluent:payment-32-regular" fontSize={20} />}
            label="Pay Advance"
            onClick={onCollectAdvanceForOtherServiceType}
            isLoading={_isLoading}
          />
        )}

        {_x_km_dispatch && (
          <Center flexDirection={"column"}>
            <Text fontSize={"sm"} textAlign={"center"} mb={-6} mt={10}>
              Distance between Pickup and Drop location is more than {convertDistance(X_DISTANCE)} KM, Please request central dispatch team.
            </Text>
            <ActionButton
              isDisabled={!_isReadyToDispatch}
              leftIcon={<Icon icon="ph:air-traffic-control-bold" fontSize={20} />}
              label="Request Central Dispatch"
              onClick={xDistanceClick}
              isLoading={_isLoading}
            />
          </Center>
        )}
      </Flex>
    </Box>
  );
}
