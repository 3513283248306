import { Box, Flex, Heading, Switch } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { ChangeEvent } from "react";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { createCaseCovidCaseOverride } from "@config/event";

export default function CovidSwitch() {
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateCovidSwitch } = useOrderAction();

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    createCaseCovidCaseOverride({ orderId: localOrderChanges?.orderId, isCovidCase: val });
    __updateCovidSwitch(val);
  };

  return (
    <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
      <Flex justify={"space-between"} align={"center"}>
        <Flex align={"center"} mt={1}>
          <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={2} bg={"purple.100"} color={"purple.800"}>
            <Icon icon={"fluent:patient-24-filled"} fontSize={20} />
          </Flex>
          <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
            {"Covid Case"}
          </Heading>
        </Flex>
        <Switch onChange={handleSwitch} isChecked={localOrderChanges?.isCovidCase} colorScheme="purple" size="md" />
      </Flex>
    </Box>
  );
}
