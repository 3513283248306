import DropDownSection from "../atom/DropDownSection";
import { DEPARTMENT_OPTIONS } from "../../config/constant";
import React from "react";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";

const DepartmentTypeDropDown = React.memo(() => {
  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateDepartment } = useOrderAction();

  const onSelect = (value: any) => {
    __updateDepartment(value?.value);
  };

  return (
    <DropDownSection
      defaultValue={order.department}
      value={localOrderChanges?.department}
      icon="mingcute:department-line"
      title="Department"
      options={DEPARTMENT_OPTIONS}
      onSelect={onSelect}
      maxWidth={200}
    />
  );
});

export default DepartmentTypeDropDown;
