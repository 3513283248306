import { Button, ButtonProps } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import React from "react";

interface IMyButton extends ButtonProps {
  label: string;
  icon?: string;
}

export default function MyButton({ label, icon = "", ...rest }: IMyButton) {
  return (
    <Button rounded={"full"} colorScheme="primary" px={8} fontSize={"xs"} size={"sm"} fontWeight={"semibold"} {...rest} leftIcon={icon ? <Icon icon={icon} /> : undefined}>
      {label}
    </Button>
  );
}
