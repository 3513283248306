import { Box, Flex, Heading, Input, Textarea, Tooltip } from "@chakra-ui/react";

import { Icon } from "@iconify/react";
import { IKeyValue, ILabelValue } from "../../config/types";
import { InfoIcon } from "@chakra-ui/icons";

interface IDropDownSection {
  icon: string;
  title: string;
  onSelect: (value?: IKeyValue) => void;
  isRequired?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  maxWidth?: number;
  tollTip?: string;
  defaultValue?: string;
  value?: any;
  minWidth?: number;
  isDisabled?: boolean;
}

const CustomCommentSection = ({ icon, title, onSelect, isRequired, placeholder, isLoading, isDisabled = false, minWidth, isClearable, maxWidth, tollTip, defaultValue, value }: IDropDownSection) => {
  return (
    <Box m={2}>
      <Flex justify={"space-between"} align={"start"}>
        <Box>
          <Flex align={"center"}>
            <Flex justify={"center"} align={"center"} bg={"primary.100"} borderRadius={"full"} p={1.5} mr={2} w={8} h={8} color={"primary.600"} pos={"relative"}>
              <Icon icon={icon} fontSize={14} />
              {isRequired && (
                <Box color={"red"} pos={"absolute"} fontSize={20} right={-1.5} top={-2}>
                  *
                </Box>
              )}
            </Flex>
            <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
              {title}
            </Heading>
            {tollTip && (
              <Tooltip hasArrow label={tollTip} bg="gray.800" color="white">
                <InfoIcon fontSize={"xs"} ml={2} color="primary.600" />
              </Tooltip>
            )}
          </Flex>
        </Box>
        <Box>
          {/* <Textarea
            onChange={onTextAreaChanges}
            w={"180px"}
            placeholder="Here is a sample placeholder"
            mr={2}
            fontSize={"sm"}
            p={2}
            defaultValue={currentCase?.overridenComments}
            isDisabled={isDisabled}
          /> */}
          <Textarea
            _disabled={{ bg: "#F2F2F2", color: "#999999" }}
            isDisabled={isDisabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            maxW={maxWidth}
            fontSize={"sm"}
            pl={2}
            fontWeight={"semibold"}
            value={value}
            onChange={onSelect}
            minW={minWidth}
          />
        </Box>
      </Flex>
    </Box>
  );
};
export default CustomCommentSection;
