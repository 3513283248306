import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { useSatthiPricing } from "@hooks/api/useCaseApi";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useRedStore } from "@store/store";
import { convertPrice } from "@utils";
import { debounce } from "lodash";
import { memo, useEffect, useMemo } from "react";

const RedMargin = memo(({ price, addonPrice }: { price: number; addonPrice: number }) => {
  const [distance, city, selectedAddons, constToOwner] = useRedStore(state => [state.price.distance, state.localOrderChanges.city, state.selectedAddons, state.constToOwner]);
  const { execute, ...reset } = useSatthiPricing();

  const db = useMemo(() => debounce(execute, 500), [execute]);

  // this depends on
  useEffect(() => {
    if (parseInt(distance) > 0 && !!city) {
      db();
    }
  }, [distance, city, selectedAddons]);

  return (
    <Box p={2} mt={4} borderWidth={1} borderRadius={"md"} borderColor={"black"} borderStyle={"dashed"}>
      <Flex justify={"space-between"} align={"start"}>
        <Text fontWeight={"normal"} fontSize={"sm"} mt={-0.75}>
          Expected RED Margin if Partner Vehicle is Assigned
        </Text>
      </Flex>
      <Flex justify={"space-between"} align={"start"} mt={4}>
        <Flex align={"center"} color={"primary.500"}>
          <Icon icon={"solar:tag-price-bold"} />
          <Text fontWeight={"semibold"} fontSize={"sm"} mt={-0.75} ml={2}>
            Expected partner cost
          </Text>
        </Flex>
        {reset?.isLoading ? (
          <Icon icon={"eos-icons:three-dots-loading"} fontSize={32} />
        ) : (
          <Text fontWeight={"bold"} fontSize={"md"}>
            ₹ {convertPrice(constToOwner)}
          </Text>
        )}
      </Flex>
      <Flex justify={"space-between"} align={"start"} mt={0}>
        <Flex align={"center"} color={"primary.500"}>
          <Icon icon={"ri:discount-percent-fill"} />
          <Text fontWeight={"semibold"} fontSize={"sm"} mt={-0.75} ml={2}>
            RED Margin
          </Text>
        </Flex>
        {reset?.isLoading ? (
          <Icon icon={"eos-icons:three-dots-loading"} fontSize={32} />
        ) : (
          <Text fontWeight={"bold"} fontSize={"md"} color={"red"}>
            {parseInt((((price + addonPrice - constToOwner) / (price + addonPrice)) * 100)?.toFixed(2))}%
          </Text>
        )}
      </Flex>
    </Box>
  );
});

export default RedMargin;

{
  /* <Flex justify={"space-between"} align={"start"}>
        <Flex justify={"center"} align={"start"}>
          <Icon icon="streamline:discount-percent-badge-solid" fontSize={18} />
          <Text fontWeight={"semibold"} fontSize={"xs"} mt={-0.75}>
            Expected RED Margin if Partner Vehicle is Assigned
          </Text>
        </Flex>
        {reset?.isLoading ? (
          <Icon icon={"eos-icons:three-dots-loading"} fontSize={32} />
        ) : (
          <Stack minW={"20"} justify={"end"} align={"end"}>
            <Text fontWeight={"bold"} fontSize={"md"} color={"red"}>
              {((price + addonPrice - constToOwner) / (price + addonPrice))?.toFixed(2)}%
            </Text>
            <Text fontWeight={"bold"} fontSize={"md"}>
              ₹ {convertPrice(constToOwner)}
            </Text>
          </Stack>
        )}
      </Flex>  */
}
