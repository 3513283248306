import React, { useState, useEffect } from "react";
import { AbsoluteCenter, Box, Button, Spinner, Stack, Text, Flex } from "@chakra-ui/react";
import { GLOBAL_SEARCH_SERVICE_TYPE } from "../../config/constant";
import { convertToGoogleGeo, showErrorToast, updateAddressState, TextMaker, flattenObject } from "@utils";
import { find } from "lodash";
import { IPlaceAddress, WaypointsEntity } from "@config/types";
import { GoogleMapBlock } from "@components/molecules/LocationMap";
import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useGoogleMaps } from "@components/molecules/GoogleMapsProvider";
import { createCaseConfirmWaypointFromMap } from "@config/event";
import { useParams } from "react-router-dom";

interface Props {
  onClose: () => void;
  waypoints: any[];
  serviceType: string;
}

type TAddressMode = "pickup" | "dropoff";

const MapLocationPicker: React.FC<Props> = ({ waypoints, serviceType, onClose }) => {
  const { isLoaded } = useGoogleMaps();
  const { id } = useParams();
  const [locationTypeSelection] = useRedStore(state => [state.locationTypeSelection]);

  const [selectedMode, setSelectedMode] = useState<TAddressMode | undefined>();
  const [pickupAddress, setPickupAddress] = useState<WaypointsEntity | undefined>();
  const [dropAddress, setDropAddress] = useState<WaypointsEntity | undefined>();

  const { __updateWaypoints } = useOrderAction();

  useEffect(() => {
    if (Array.isArray(waypoints) && waypoints.length > 1) {
      const pickup = find(waypoints, ["type", "pickup"]);
      const dropoff = find(waypoints, ["type", "dropoff"]);

      setPickupAddress(pickup);
      setDropAddress(dropoff);

      const defaultLocationMode = find(waypoints, item => !item?.sourceId)?.type;

      // Error: when user select both pickup and drop as partner hospital.
      if (!defaultLocationMode) {
        showErrorToast("Pickup and Drop is Partner Hospital Address.");
        return;
      }

      setSelectedMode(locationTypeSelection?.type === "pickup" ? "pickup" : "dropoff");
    }
  }, [waypoints]);

  function handleAddressSelection(addressObj: IPlaceAddress) {
    if (selectedMode === "pickup") {
      const waypoint = { ...pickupAddress };
      updateAddressState(addressObj, waypoint, setPickupAddress);
    } else {
      const waypoint = { ...dropAddress };
      updateAddressState(addressObj, waypoint, setDropAddress);
    }
  }

  const handleAddressModeSelection = (mode: TAddressMode) => {
    setSelectedMode(mode);
  };

  const coordinates = selectedMode === "pickup" ? pickupAddress?.geo : dropAddress?.geo;

  const handleSubmit = () => {
    let pickupRef: any = null;
    let dropOffRef: any = null;

    if (!pickupAddress?.sourceId) {
      pickupRef = pickupAddress;
    }
    if (!dropAddress?.sourceId) {
      dropOffRef = dropAddress;
    }

    const currentWaypoint = flattenObject(selectedMode === "pickup" ? pickupAddress : dropAddress);
    createCaseConfirmWaypointFromMap({ ...currentWaypoint, orderId: id || "" });
    __updateWaypoints(pickupRef, dropOffRef);
    onClose();
  };

  return !isLoaded ? (
    <AbsoluteCenter>
      <Spinner />
    </AbsoluteCenter>
  ) : (
    <Box w={"100vw"} h={"100vh"} overflow={"hidden"} pos={"relative"}>
      {coordinates && <GoogleMapBlock onDragEnd={handleAddressSelection} coordinates={convertToGoogleGeo(coordinates)} canSelectOutsideIndia={GLOBAL_SEARCH_SERVICE_TYPE?.includes(serviceType)} />}
      <Box pos="fixed" bottom={0} w="full" minH="32">
        <Box bg="white" shadow="xl" borderRadius="2xl" h="full" p={4} borderWidth={1} borderColor="gray.300">
          <Text fontWeight="semibold">Select {selectedMode === "dropoff" ? "Drop" : "Pickup"} Location</Text>
          <Stack mt={2} gap={2}>
            {selectedMode === "pickup" ? (
              <LocationRender isSelected={selectedMode === "pickup"} mode={"pickup"} onClick={() => handleAddressModeSelection("pickup")} location={pickupAddress} />
            ) : (
              <LocationRender isSelected={selectedMode === "dropoff"} mode={"dropoff"} onClick={() => handleAddressModeSelection("dropoff")} location={dropAddress} />
            )}
            <Button onClick={handleSubmit} fontWeight={"semibold"}>
              Confirm
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default MapLocationPicker;

interface ILocationRender {
  mode: TAddressMode;
  isSelected: boolean;
  onClick: () => void;
  location?: WaypointsEntity;
}
const LocationRender: React.FC<ILocationRender> = ({ mode, isSelected, onClick, location }) => {
  const bgColor = isSelected ? "green.100" : "gray.100";
  const borderColor = isSelected ? "green.300" : "gray.300";
  const sourceMap = useRedStore(state => state.sourceMap);

  const isDisabled = !!location?.sourceId;

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  const sourceId = location?.sourceId;
  const sourceName = sourceId ? sourceMap[sourceId]?.name : "-";

  return isDisabled ? (
    <Box my={2}>
      <TextRenderWithIcon icon="material-symbols:location-on-outline" title={`${mode} Point`} description={sourceName} subTitle={TextMaker(location?.sourceId)} />
    </Box>
  ) : (
    <Box key={mode} bg={bgColor} borderColor={borderColor} borderWidth={1} onClick={handleClick} px={4} py={2} borderRadius="md" opacity={isDisabled ? 0.5 : 1}>
      <Flex align={"center"} gap={2}>
        <Box>
          <Box h={4} w={4} backgroundColor={"white.200"} borderRadius={"full"} borderWidth={5} borderColor={!isSelected ? "gray.400" : "green.500"}></Box>
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="semibold" noOfLines={10}>
            {location ? location?.address?.location : "Click to Select a Location"}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
