import { useEffect } from "react";
import usePlace from "../../hooks/action/usePlace";
import { Text } from "@chakra-ui/react";

interface IPlacePicker {
  onSelect: (e: any) => void;
  placeholder?: string;
  defaultValue: any;
  value?: any;
  isLocalizationEnabled?: boolean;
}

const PlacePicker = ({ onSelect, placeholder, defaultValue, value, isLocalizationEnabled }: IPlacePicker) => {
  const [inputRef, payload] = usePlace({
    onAddressSelect: (payload: any) => {
      onSelect({ value: payload });
    },
    isLocalizationEnabled: !!isLocalizationEnabled,
  });

  const handleFocus = (event: any) => {
    const nonDesktop = window.innerWidth < 1021;
    nonDesktop && event.target.select();
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current.value = defaultValue?.address?.name || defaultValue?.address?.location || defaultValue?.address?.formatted_address || "";
    }
  }, [defaultValue?.address]);

  return (
    <>
      <input
        defaultValue={defaultValue?.address?.name ? defaultValue?.address?.name : defaultValue?.address?.location}
        onFocus={handleFocus}
        placeholder={placeholder || "Please type address"}
        className="custom-location-search-input"
        ref={inputRef}
      />
      <Text fontSize={"sm"} m={2}>
        {defaultValue?.address?.location}
      </Text>
    </>
  );
};
export default PlacePicker;
