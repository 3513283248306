import { ATTRIBUTE_OPTIONS } from "../../config/constant";
import DropDownSection from "../atom/DropDownSection";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { memo } from "react";
import { createCaseAttributionChangeTo } from "@config/event";

const AttributionDropDown = memo(() => {
  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateAttribute } = useOrderAction();

  const onSelect = (itm: any) => {
    createCaseAttributionChangeTo({ attributionCode: itm?.value, attributionName: itm?.label, orderId: order?.orderId });
    __updateAttribute(itm.value);
  };

  return (
    <DropDownSection
      defaultValue={order?.attributedTo}
      value={localOrderChanges.attributedTo}
      icon="ic:baseline-window"
      title="Attribution"
      options={ATTRIBUTE_OPTIONS}
      onSelect={onSelect}
      maxWidth={200}
    />
  );
});

export default AttributionDropDown;
