import { Box, Flex, Heading, Text, Tooltip } from "@chakra-ui/react";

import { Icon } from "@iconify/react";
import { InfoIcon } from "@chakra-ui/icons";

interface IKeyValueRender {
  icon?: string;
  title: string;
  rightSideComponent: any;
  subTitle?: string;
  tollTip?: string;
}

const KeyValueRenderV2 = ({ icon, title, subTitle, rightSideComponent, tollTip }: IKeyValueRender) => {
  return (
    <Box m={2}>
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <Flex align={"center"}>
            {icon && (
              <Flex justify={"center"} align={"center"} bg={"primary.100"} borderRadius={"full"} mr={2} color={"primary.600"} h={8} w={8}>
                <Icon icon={icon} fontSize={18} />
              </Flex>
            )}
            <Box>
              <Heading fontWeight={"semibold"} fontSize={"xs"} textTransform={"capitalize"}>
                {title}
              </Heading>
              {subTitle && <Text fontSize={"2xs"}>{subTitle}</Text>}
            </Box>
            {tollTip && (
              <Tooltip hasArrow label={tollTip} bg="gray.800" color="white">
                <InfoIcon fontSize={"xs"} ml={2} color="primary.600" />
              </Tooltip>
            )}
          </Flex>
        </Box>
        <Box>{rightSideComponent}</Box>
      </Flex>
    </Box>
  );
};
export default KeyValueRenderV2;
