import { Stack, Flex, Heading, Text, Card, Tag, TagLabel, Divider } from "@chakra-ui/react";
import { AMBULANCE_MAPPER_KEY } from "../../config/constant";
import { Icon } from "@iconify/react";
import { useRedStore } from "@store/store";

import { convertPrice, convertToCurrency, secondsToHoursAndMinutes } from "@utils";
import { ISelectedVehicleType } from "@config/types";

type Props = {
  selectedVehicle: ISelectedVehicleType;
  isSelected: boolean;
};

const AmbulanceVariant = ({ selectedVehicle, isSelected }: Props) => {
  const { label, icon, message, value } = selectedVehicle;
  const nearbyVehicle: any = useRedStore(state => state.nearbyVehicle);

  const subtype = AMBULANCE_MAPPER_KEY[value];

  const { eta, price, quantity } = nearbyVehicle && nearbyVehicle[subtype];
  const finalEta = eta ? secondsToHoursAndMinutes(eta) : "";

  return (
    <Card
      shadow={"sm"}
      mb={4}
      borderRadius={"2xl"}
      _hover={{ shadow: "md" }}
      colorScheme="orange"
      _selected={{ bg: isSelected ? "orange.100" : "white", borderWidth: isSelected ? 2 : 1, borderColor: isSelected ? "orange.400" : "gray.300" }}
      cursor={"pointer"}
      aria-selected="true"
      variant={isSelected ? "filled" : "outline"}
    >
      <Stack pb={2}>
        <Flex justify={"space-between"} w={"full"} align={"start"} p={4} pb={2}>
          <Flex gap={4}>
            <Flex minH={10} minW={10} maxH={10} bg={"orange.300"} justify={"center"} align={"center"} borderRadius={"xl"} color={"orange.800"}>
              <Icon icon={icon} fontSize={20} />
            </Flex>
            <Stack justify={"center"}>
              <Stack flexWrap={"wrap"}>
                <Heading fontWeight={"semibold"} fontSize={"sm"} mr={2}>
                  {label}
                </Heading>
                <Flex gap={2}>
                  <Tag size={"sm"} variant="subtle" colorScheme={quantity ? "green" : "red"} px={2}>
                    <TagLabel fontWeight={"semibold"} minWidth={20}>
                      Available: {quantity || 0}
                    </TagLabel>
                  </Tag>
                  {finalEta && (
                    <Tag size={"sm"} variant="subtle" colorScheme="blue" px={2}>
                      <TagLabel fontWeight={"semibold"} minWidth={20}>
                        ETA: {finalEta || "-"}
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
              </Stack>
            </Stack>
          </Flex>

          <Stack align={"center"} ml={2} pos={"absolute"} right={4} top={2}>
            <Text textAlign={"center"} fontSize={"xl"} fontWeight={"black"} color={"black"}>
              {(price && convertToCurrency(convertPrice(price))) || "-"}
            </Text>
          </Stack>
        </Flex>
        <Divider />
        <Text fontWeight={"normal"} fontSize={"sm"} opacity={1} px={4} pt={0}>
          {message}
        </Text>
      </Stack>
      {/* <Divider />
      <Flex px={4} py={2}>
        <Tag size={"sm"} borderRadius="full" variant="solid" colorScheme="green">
          <CircleIcon boxSize={6} />
          <TagLabel>Green</TagLabel>
        </Tag>
      </Flex> */}
    </Card>
  );
};

export default AmbulanceVariant;
