// src/context/GoogleMapsProvider.tsx
import React, { createContext, useContext } from "react";
import { useLoadScript } from "@react-google-maps/api";

interface GoogleMapsContextType {
  isLoaded: boolean;
  loadError: Error | undefined;
}

const GoogleMapsContext = createContext<GoogleMapsContextType | undefined>(undefined);

const MAP_INIT_OBJ: any = {
  googleMapsApiKey: import.meta.env.VITE_PLACE_API_KEY as string,
  libraries: ["places"],
};

export const GoogleMapsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript(MAP_INIT_OBJ);

  return <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>{children}</GoogleMapsContext.Provider>;
};

export const useGoogleMaps = (): GoogleMapsContextType => {
  const context = useContext(GoogleMapsContext);
  if (!context) {
    throw new Error("useGoogleMaps must be used within a GoogleMapsProvider");
  }
  return context;
};
