import { MAP_INIT_OBJ } from "@config/constant";
import { IPlaceAddress, WaypointsEntity } from "@config/types";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { convertToGoogleGeo, parseGooglePlaceAddressFromMap, showErrorToast } from "@utils";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import mapPin from "/assets/png/pin.png";
import { isDragActive } from "framer-motion";
import { createCaseOpenGoogleMap } from "@config/event";
import { useParams } from "react-router-dom";

interface Props {
  waypoints: WaypointsEntity[];
}

interface Coordinates {
  lat: number;
  lng: number;
}

type TAddressMode = "pickup" | "drop";

const ADDRESS_MODE: TAddressMode[] = ["pickup", "drop"];

interface IUseLocationMap {
  onAddressSelect?: any;
}

// export const useLocationMap = ({ onAddressSelect }: IUseLocationMap) => {
//   const [mapCenter, setMapCenter] = useState<Coordinates>({ lat: 17.385, lng: 78.4867 });
//   const [address, setAddress] = useState<any>();
//   const mapRef = useRef<google.maps.Map | null>(null);
//   const markerRef = useRef<google.maps.Marker | null>(null);

//   const geocoder = new google.maps.Geocoder();

//   const getAddress = (latLng: any, cb: any) => {
//     return geocoder.geocode({ location: latLng }, (results: any, status) => {
//       if (status === "OK") {
//         if (results[0]) {
//           const addressObj = parseGooglePlaceAddressFromMap(results[0]);
//           if (!addressObj?.postcode) {
//             showErrorToast("Please select a valid address.");
//             return;
//           }

//           cb({ ...addressObj, geo: latLng });
//         } else {
//           showErrorToast("No results found");
//         }
//       } else {
//         showErrorToast("Geocoder failed due to: " + status);
//       }
//     });
//   };

//   // const updateGeo = useCallback(
//   //   (coordinates: Coordinates) => {
//   //     if (mapRef.current) {
//   //       const newCenter = new google.maps.LatLng(coordinates);
//   //       mapRef.current.setCenter(newCenter); // This sets the new center of the map
//   //       mapRef.current.setZoom(17); // Optionally set the zoom level to focus closely on the new center
//   //       markerRef.current?.setPosition(coordinates); // Update the marker position to the new center
//   //       setMapCenter(coordinates); // Update the center state
//   //       // getAddress(coordinates); // Fetch the address for the new center
//   //     }
//   //   },
//   //   [mapCenter, mapRef]
//   // );

//   const onLoad = useCallback(
//     (map: google.maps.Map) => {
//       mapRef.current = map;
//       const bounds = new google.maps.LatLngBounds();
//       bounds.extend(new google.maps.LatLng(mapCenter.lat, mapCenter.lng));
//       map.fitBounds(bounds);
//       map.setZoom(20);

//       const marker = new google.maps.Marker({
//         position: map.getCenter(),
//         map,
//         icon: {
//           url: mapPin,
//           scaledSize: new google.maps.Size(50, 50),
//         },
//       });

//       markerRef.current = marker;
//     },
//     [mapCenter]
//   );

//   const onDragEnd = () => {
//     if (mapRef.current) {
//       const newCenter: any = mapRef.current.getCenter();

//       setMapCenter({
//         lat: newCenter?.lat() ? newCenter?.lat() : mapCenter.lat,
//         lng: newCenter?.lng() ? newCenter?.lng() : mapCenter.lng,
//       });

//       // getAddress(new google.maps.LatLng(newCenter?.lat(), newCenter?.lng()));
//     }
//   };

//   const debounceDrag = debounce(onDragEnd, 200);

//   const onUnmount = useCallback(() => {
//     mapRef.current = null;
//   }, []);

//   const onCenterChanged = () => {
//     const newCenter = mapRef.current?.getCenter();
//     markerRef.current?.setPosition(newCenter);
//   };

interface IGoogleMapBlock {
  onDragEnd: (info: IPlaceAddress) => void;
  coordinates: Coordinates;
  isDraggable?: boolean;
  canSelectOutsideIndia?: boolean;
}

interface IGetAddress {
  latLng: Coordinates;
  cb: (resp: IPlaceAddress) => void;
}

const GoogleMapBlock = ({ onDragEnd, coordinates, isDraggable = true, canSelectOutsideIndia = false }: IGoogleMapBlock) => {
  const { id } = useParams();
  const [mapCenter, setMapCenter] = useState<Coordinates>(coordinates);
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const geocoder = new google.maps.Geocoder();

  const getAddress = ({ latLng, cb }: IGetAddress) => {
    return geocoder.geocode(
      {
        location: latLng,
        // componentRestrictions: {
        //   country: "in,bt",
        // },
      },
      (results: any, status) => {
        if (status === "OK") {
          if (results[0]) {
            const addressObj = parseGooglePlaceAddressFromMap(results[0]);
            console.log(addressObj);

            if (!canSelectOutsideIndia && addressObj?.country !== "India") {
              showErrorToast("Address outside of India not allowed.");
              return;
            }

            if (!addressObj?.pincode) {
              showErrorToast("This Address doesn’t have PIN code.");
              return;
            }

            cb({ ...addressObj, geo: latLng });
          } else {
            showErrorToast("No results found");
          }
        } else {
          showErrorToast("Geocoder failed due to: " + status);
        }
      }
    );
  };

  const onLoad = (map: google.maps.Map) => {
    id && createCaseOpenGoogleMap({ orderId: id, lat: mapCenter?.lat, lng: mapCenter?.lng });
    mapRef.current = map;
    const bounds = new google.maps.LatLngBounds();

    // Extend the bounds to cover a wider area
    bounds.extend(new google.maps.LatLng(mapCenter.lat + 0.0008, mapCenter.lng + 0.0008)); // North-East
    bounds.extend(new google.maps.LatLng(mapCenter.lat - 0.0008, mapCenter.lng - 0.0008)); // South-West

    map.fitBounds(bounds);

    const marker = new google.maps.Marker({
      position: map.getCenter(),
      map,
      icon: {
        url: mapPin,
        scaledSize: new google.maps.Size(50, 50),
      },
    });
    markerRef.current = marker;
  };

  const onDragEndHandler = () => {
    if (mapRef.current) {
      const newCenter: any = mapRef.current.getCenter();
      const location: any = {
        lat: newCenter?.lat(),
        lng: newCenter?.lng(),
      };

      getAddress({
        latLng: location,
        cb: address => {
          onDragEnd(address);
        },
      });

      setMapCenter(location);
    }
  };

  const debounceDrag = debounce(onDragEndHandler, 200);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const onCenterChanged = () => {
    const newCenter = mapRef.current?.getCenter();
    markerRef.current?.setPosition(newCenter);
  };

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "80vh" }}
      center={coordinates}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={debounceDrag}
      zoom={17}
      options={{
        draggable: isDraggable,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        gestureHandling: "greedy",
        clickableIcons: false,
      }}
      onCenterChanged={onCenterChanged}
    ></GoogleMap>
  );
};

export { GoogleMapBlock };
