import DropDownSection from "../atom/DropDownSection";
import { SERVICE_TYPE_MAP } from "../../config/constant";
import React from "react";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";

const ServiceTypeDropDown = React.memo(() => {
  const order = useRedStore(state => state.order);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { __updateService } = useOrderAction();

  const onSelect = (value: any) => {
    __updateService(value?.value);
  };

  return (
    <DropDownSection
      isDisabled
      defaultValue={order.serviceType}
      value={localOrderChanges?.serviceType}
      icon="line-md:medical-services-filled"
      title="Service Type"
      options={Object.values(SERVICE_TYPE_MAP)}
      onSelect={onSelect}
      maxWidth={200}
    />
  );
});

export default ServiceTypeDropDown;
