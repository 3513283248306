import { Text } from "@chakra-ui/react";
import React from "react";

interface IErrorRender {
  errors: any;
}
export default function ErrorRender({ errors }: IErrorRender) {
  return errors ? (
    <Text fontWeight={"normal"} fontSize={"xs"} color={"red.500"} my={1}>
      {errors.message}
    </Text>
  ) : (
    <></>
  );
}
